import React from "react";
import { OfflineStyle } from "./style";
import WifiIcon from "./assets/WifiIcon";
import { useTranslation } from "react-i18next";
import UseNetwork from "./useNetwork";

const OfflinePopup = () => {
  const { t } = useTranslation();

  const isOnline = UseNetwork();

  if (isOnline) return <></>;

  return (
    <OfflineStyle>
      <div className="offline-popup">
        <div className="popup-content">
          <div className="header">
            <h2>{t("toast_messages.no_internet")}</h2> <WifiIcon />
          </div>
          <p>{t("toast_messages.check_internet")}</p>
        </div>
      </div>
    </OfflineStyle>
  );
};

export default OfflinePopup;

import Autocomplete from "@mui/lab/Autocomplete";
import { TextField } from "@mui/material";
import { RemoveIcon } from "assets/svgs";
import { useApi } from "hooks/useApi/useApiHooks";
import useDebounce from "hooks/useDebounce";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { Control, Controller, FieldPath } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TRules } from "types/form.types";
import { AutoCompleteStyled } from "./AutoCompleteForm.style";
import { getValueFromOptions } from "./utils/AutoComplete.utils";

export interface IAutocompleteForm<FormNames extends Record<string, any>> {
  control: Control<FormNames>;
  name: FieldPath<FormNames>;
  options?: any[];
  optionsUrl?: string;
  returnOnlyId?: boolean;
  label?: string;
  rules?: TRules<FormNames>;
  placeholder?: any;
  disabled?: boolean;
  nameProp?: string;
  exQueryParams?: object;
  dataProp?: string;
  multiple?: boolean;
  getDataLength?: any;
  reRender?: boolean;
  canReGet?: boolean;
  clearable?: boolean;
  disabledOptions?: string[];

  onInputChange?: (value: string) => void;
  getLabelOption: (option: Record<string, any>) => string;
  onChange?: (options: any | null | any[]) => void;
  renderOption?: any;
  uniqueId?: string;
}

function AutoCompleteForm<FormNames extends Record<string, any>>({
  control,
  name,
  options = [],
  optionsUrl = "", // 😐
  returnOnlyId = true,
  label = "",
  rules = { required: { value: true, message: "general.required" } },
  disabled,
  placeholder,
  exQueryParams = {},
  dataProp = "data" as const, //😐
  onChange,
  onInputChange,
  multiple = false,
  getDataLength,
  reRender,
  canReGet,
  clearable = true,
  disabledOptions = [],
  renderOption,
  uniqueId = "_id",
  getLabelOption,
}: IAutocompleteForm<FormNames>) {
  const [queryParams, setQueryParams] = useState<{ search?: string }>();
  const [search, setSearch] = useState<string>();
  const { debouncedValue: debVal, isDebouncing } = useDebounce(search, 600);
  const { t } = useTranslation();

  useEffect(() => {
    setQueryParams({
      search,
    });
  }, [debVal]);

  const {
    data: OptionsData,
    isFetching,
    refetch,
  } = useApi<any[]>(
    optionsUrl,
    {
      ...queryParams,
      ...exQueryParams,
    },
    {
      enabled: !!optionsUrl,
      suspense: false,
      onSuccess(data) {
        getDataLength?.(get(data, dataProp)?.length);
      },
    }
  );

  useEffect(() => {
    if (canReGet) {
      refetch();
    }
  }, [reRender]);

  const OPTIONS = get(OptionsData, dataProp) || options || [];

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <AutoCompleteStyled
            multiple={multiple}
            disabled={disabled}
            error={fieldState?.error}
          >
            {label && (
              <label htmlFor={name}>
                {label}{" "}
                {rules.required && <span className="mt-1 text-error">*</span>}
              </label>
            )}
            <Autocomplete
              options={OPTIONS}
              getOptionLabel={(option) => getLabelOption(option) || ""}
              getOptionDisabled={(option) =>
                disabledOptions.includes(option._id)
              }
              multiple={multiple}
              renderOption={renderOption || undefined}
              clearIcon={clearable ? <RemoveIcon /> : null}
              {...field}
              onChange={(e, data: any) => {
                field.onChange(
                  returnOnlyId
                    ? multiple
                      ? data?.map((e: any) => e._id)
                      : data?._id!
                    : data
                );
                onChange?.(data);
              }}
              loading={isFetching || isDebouncing}
              disabled={disabled}
              sx={{
                "& fieldset": { border: "none" },
              }}
              loadingText="izlamoqda..."
              isOptionEqualToValue={(option, value) =>
                option[uniqueId] === value[uniqueId]
              }
              noOptionsText={!isDebouncing && t("general.no_data")}
              value={
                returnOnlyId
                  ? multiple
                    ? getValueFromOptions(OPTIONS, field.value, true)
                    : getValueFromOptions(OPTIONS, field.value)
                  : field.value
              }
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    onChange={(e) => {
                      !field.value && setSearch(e?.target?.value || "");
                      onInputChange?.(e?.target?.value || "");
                    }}
                    onBlur={() => !field.value && setSearch(undefined)}
                    value={params.inputProps.value}
                    variant="outlined"
                    error={!!fieldState.error}
                    fullWidth
                    placeholder={placeholder}
                  />
                  {fieldState?.error && (
                    <>
                      <h6 className="text-error mt-1">
                        {t(fieldState.error.message!)}
                      </h6>
                    </>
                  )}
                </>
              )}
            />
          </AutoCompleteStyled>
        );
      }}
    />
  );
}

export default AutoCompleteForm;

import { FC } from "react";

export interface IFooterProps {}

export const Footer: FC<IFooterProps> = ({}) => {
  return (
    <>
      <div className="flex gap-2 p-1 px-2 items-center">
        <div className="h-3 w-3 rounded-full bg-bgDisabled"></div>
        <div className="border h-4 w-36 rounded-full"></div>
      </div>
      <div className="flex items-center justify-center">
        <span className="w-20 h-1 bg-bgDisabled rounded-lg"></span>
      </div>
    </>
  );
};

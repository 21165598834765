import { FC } from "react";
import { ICRUDManagerProps } from ".";
import { CommonButton, CommonModal } from "components";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { CloseBtn } from "styles/global.style";
import { CloseIcon } from "assets/svgs";
import { useApiMutation } from "hooks/useApi/useApiHooks";

export interface IDeleteModalProps extends ICRUDManagerProps {}

export const DeleteModal: FC<IDeleteModalProps> = ({ action, setAction }) => {
  const { t } = useTranslation();
  const { mutateAsync } = useApiMutation(
    `sms-templates/${action?.data?._id}`,
    "delete"
  );
  function handleClose() {
    setAction(null);
  }

  return (
    <Modal
      title={t("general.realy_delete")!}
      centered
      open={!!action}
      onCancel={handleClose}
      closeIcon={
        <CloseBtn onClick={handleClose}>
          <CloseIcon />
        </CloseBtn>
      }
      footer={[
        <CommonButton
          title={t("general.no")}
          className="grey"
          key="back"
          type="button"
          onClick={handleClose}
          style={{ marginRight: "15px", maxWidth: "280px" }}
        />,
        <CommonButton
          title={t("general.yes")}
          style={{ maxWidth: "280px" }}
          className="main"
          onClick={function () {
            mutateAsync({}).then(function () {
              setAction(null);
            });
          }}
        />,
      ]}
    />
  );
};

import { FC, useEffect, useState } from "react";
import { useGetSmsVariables } from "../../hooks/useGetSmsVariables";
import { useFormContext, useWatch } from "react-hook-form";

export interface ISuggessionsProps {}

export const Suggessions: FC<ISuggessionsProps> = ({}) => {
  const smsVariables = useGetSmsVariables();
  const { control, setValue, setFocus } = useFormContext();
  const content = useWatch({ control: control, name: "content" });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (content) {
      const wantToWriteVariable = content
        .replaceAll("\n", " ")
        .split(" ")
        .includes("{");

      if (wantToWriteVariable) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [content]);

  return isOpen ? (
    <div className="bg-bgDisabled rounded-lg p-2">
      {smsVariables.map((item) => (
        <div
          onClick={function (e) {
            e.stopPropagation();
            setValue(
              "content",
              content.slice(0, content.length - 1) + `{${item.value}}`
            );
            setFocus("content", { shouldSelect: false });
          }}
          className="hover:bg-bgMain cursor-pointer"
        >{`${item.keyword} | {${item.value}}`}</div>
      ))}
    </div>
  ) : null;
};

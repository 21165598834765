import { FC, useState } from "react";
import { Edit } from "./Edit";
import { View } from "./View";
import { IIntegration } from "../../../constants/Integration.type";
import { Preview } from "./Preview";

export interface IModalContentProps {
  data: IIntegration;
  refetch: any;
}

export const ModalContent: FC<IModalContentProps> = ({ data, refetch }) => {
  const [mode, setMode] = useState<"edit" | "view">("view");

  if (!data?.isInstalled) {
    return (
      <div className="modal-content">
        <Preview data={data} />
      </div>
    );
  }

  return (
    <div className="modal-content">
      {mode === "edit" ? (
        <Edit refetch={refetch} data={data} setMode={setMode} />
      ) : (
        <View data={data} setMode={setMode} />
      )}
    </div>
  );
};

import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import { FormRadioStyled } from "./Radio.style";
import { useTranslation } from "react-i18next";

interface IRadioBox {
  name: string;
  control: Control<any>;
  label?: string;
  radios: { value: string; label: string }[];
  rules?: any;
  onChage?: any;
  defaultValue?: string;
}
const RadioBox = ({
  name,
  control,
  label,
  radios,
  defaultValue,
  onChage,
  rules = { required: { value: true, message: "general.required" } },
}: IRadioBox) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <FormRadioStyled>
            {label && <FormLabel id={field.name}>{label}</FormLabel>}
            <RadioGroup
              aria-labelledby={field.name}
              name={field.name}
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e);
                onChage?.(e);
              }}
            >
              {radios.map((radio) => (
                <FormControlLabel
                  value={radio.value}
                  control={<Radio />}
                  label={radio.label}
                />
              ))}
            </RadioGroup>
            {fieldState.error && (
              <>
                <h6 className="text-error mt-1">
                  {t(fieldState.error.message!)}
                </h6>{" "}
              </>
            )}
          </FormRadioStyled>
        )}
      />
    </>
  );
};

export default RadioBox;

import { Grid } from "@mui/material";
import { AutoCompleteForm } from "components";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface ISubjectsProps {}

export const Subjects: FC<ISubjectsProps> = ({}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const classIds = useWatch({ control, name: "classIds" });

  if (!classIds) return null;

  return (
    <Grid item xs={3} md={2.4}>
      <AutoCompleteForm
        name="subjectIds"
        label={t("LABELS.SUBJECT")!}
        optionsUrl="subjects/pagin"
        multiple
        control={control}
        dataProp="data.data"
        getLabelOption={(option) => option.name}
        exQueryParams={{
          page: 1,
          limit: 200,
        }}
      />
    </Grid>
  );
};

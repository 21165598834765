import { Button, Grid } from "@mui/material";
import { SelectBtnIcon, TopToBottomIcon } from "assets/svgs";
import { AutoCompleteFilter, RangeDatePicker, Table } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderHeaderStyled, SecondRowShow } from "styles/global.style";
import { useRoomColumns } from "./parent.columns";
import { IParent } from "../types";
import dayjs from "dayjs";

export const Parents = () => {
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const thisMonthStart = dayjs().startOf("week").toISOString();
  const thisMonthEnd = dayjs().endOf("week").toISOString();

  const columns = useRoomColumns();
  const { t } = useTranslation();

  const renderHeader = (
    <OrderHeaderStyled>
      <Button className="filter_btn" onClick={() => setIsTrue(!isTrue)}>
        <div>
          <SelectBtnIcon />
          <span>{t("LABELS.FILTER.FILTER_TITLE")}</span>
        </div>
        <div className={isTrue ? "icon active" : "icon"}>
          <TopToBottomIcon />
        </div>
      </Button>
    </OrderHeaderStyled>
  );

  const renderSecondRow = (
    <SecondRowShow in={isTrue}>
      <Grid
        container
        spacing={1}
        mb={"12px"}
        display={"flex"}
        justifyContent={"flex-end"}
        paddingRight={"8px"}
      >
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            getOptionLabel={(option) => option?.grade + option?.letter}
            placeholder={t("general.class")!}
            filterName="classId"
            optionsUrl="class/pagin"
            exQueryParams={{
              limit: 200,
              page: 1,
            }}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("general.student")!}
            filterName="studentId"
            optionsUrl="students/pagin"
            exQueryParams={{
              limit: 200,
              page: 1,
            }}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            options={[
              { _id: "father", name: t("general.father") },
              { _id: "mother", name: t("general.mother") },
            ]}
            placeholder={t("general.relation")!}
            filterName="type"
            exQueryParams={{
              limit: 200,
              page: 1,
            }}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            options={[
              { _id: "true", name: t("general.yes") },
              { _id: "false", name: t("general.no") },
            ]}
            placeholder={t("general.isAppInstalled")!}
            filterName="isAppInstalled"
            exQueryParams={{
              limit: 200,
              page: 1,
            }}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <RangeDatePicker
            defaultValues={[dayjs(thisMonthStart), dayjs(thisMonthEnd)]}
          />
        </Grid>
      </Grid>
    </SecondRowShow>
  );

  return (
    <div>
      <Table<IParent>
        dataUrl="/parents"
        columns={columns}
        title={t("general.parents")}
        headerChildren={renderHeader}
        headerChildrenSecondRow={renderSecondRow}
        addButtonTitle={t("general.add_room")!}
      />
    </div>
  );
};

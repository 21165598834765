import { useTranslation } from "react-i18next";
import { array, boolean, object, string } from "yup";

export const useSmsSchema = () => {
  const { t } = useTranslation();

  const smsSchema = object().shape({
    integrationId: string().required(t("general.required")),
    studentIds: array().of(string()),
    toStudent: boolean(),
    content: string().required(t("general.required")),
    type: string(),
  });

  return smsSchema;
};

import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  min-width: 80px !important;
  height: 42px;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  border-radius: 8px !important;
  line-height: 48px;
  background-color: ${({ theme }: any) => theme.background.primary} !important;
  color: #fff !important;
  border: none;
  outline: none;

  &.cancel {
    background: ${({ theme }) => theme.hover.main} !important;
    color: ${({ theme }) => theme.background.primary} !important;
    border: none;
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.primary.disabled} !important;
    background-color: ${({ theme }) => theme.background.disabled} !important;
    border: none;
    outline: none;
  }

  .MuiButton-startIcon .MuiCircularProgress-svg {
    circle {
      stroke: ${({ theme }: any) => theme.background.primary};
    }
  }
`;

export const darkMode: any = {
  background: {
    main: "#0F2231",
    primary: "#4D8BED",
    secondary: "#0FAAC0",
    info: "#153043",
    error: "rgba(185, 55, 146,0.15)",
    dark: "#5C636A",
    body: "rgba(21, 48, 67,0.95)",
    disabled: "#727888",
    loaderBack: "rgba(0,0,0,0.3)",
    selected: "#4D8BED",
  },
  primary: {
    main: "#4D8BED",
    light: "rgb(29, 32, 33)",
    dark: "#fff",
    disabled: "#d2d2d2",
    secondary: "#e9e9e9",
  },
  secondary: {
    main: "#949494",
    light: "#e9e9e9",
    dark: "#eee",
  },
  form: {
    main: "#eef3f7",
  },
  success: {
    main: "#00c537",
  },
  black: {
    main: "rgb(255, 255, 255) ",
  },
  white: {
    main: "rgb(24, 26, 27)",
  },
  error: {
    main: "#e34a29",
    primary: "#f7536b",
  },
  info: {
    main: "#095379",
  },
  warning: {
    main: "#FBB500",
  },
  hover: {
    main: "rgba(35, 120, 144,0.1)",
    secondary: "rgba(21, 48, 67,0.1)",
  },
  scrollbar: {
    thumb: "#096BDB",
    track: "#555c6e",
  },
  border: {
    main: "#2A3F4D",
  },
  boxShadow: {
    main: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
  },
  sidebar: {
    main: "#153043",
  },
  chat: {
    sender_message_back: "#3D68FF",
    receive_message_back: "rgba(21, 48, 67,0.95)",
  },
  transparent: "transparent",
};

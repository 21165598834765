import { Tooltip } from "antd";
import { TooltipPropsWithOverlay } from "antd/es/tooltip";
import { FC } from "react";

interface TooltipType extends TooltipPropsWithOverlay {
  title: string;
  bgColor?: string;
  children?: React.ReactNode;
}

const TooltipBox: FC<TooltipType> = ({
  title,
  bgColor,
  children,
  ...props
}) => {
  return (
    <Tooltip
      title={title}
      color={bgColor || `#5C636A`}
      placement={props.placement || "topLeft"}
      {...props}
    >
      {children ? (
        children
      ) : (
        <div className="text-center">
          {title?.length > 18 ? title.slice(0, 18) + "..." : title}
        </div>
      )}
    </Tooltip>
  );
};

export default TooltipBox;

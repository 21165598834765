import { Button } from "@mui/material";
import { TextInput } from "components";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IIntegration } from "pages/settings/integrations/constants/Integration.type";
import { useApiMutation } from "hooks/useApi/useApiHooks";

export interface IEditProps {
  setMode: (state: "edit" | "view") => void;
  data: IIntegration;
  refetch: any;
}

export const Edit: FC<IEditProps> = ({ setMode, data, refetch }) => {
  const { t, i18n } = useTranslation();
  const { control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    data.fields.forEach((item) => setValue(item.fieldName, item.fieldValue));
  }, [setValue, data.fields, i18n.language]);
  const { mutateAsync } = useApiMutation(`integration`, "put");

  function onSubmit(formData: Record<string, string>) {
    const payload = {
      ...data,
      fields: data.fields.map((item) => ({
        fieldName: item.fieldName,
        fieldValue: formData[item.fieldName],
        fieldLabel: item.fieldLabel,
      })),
    };
    mutateAsync(payload).then((data) => {
      setMode("view");
      refetch();
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="modal-content__edit-title">{t("integrations.info")}</p>
      {data.fields.map((item) => (
        <>
          <TextInput
            label={item.fieldLabel[i18n.language]}
            control={control}
            name={item.fieldName}
          />
          <br />
        </>
      ))}

      <div className="modal-content__button-wrapper">
        <Button
          onClick={() => setMode("view")}
          className="modal-content__cancel-button"
        >
          {t("general.cancel")}
        </Button>
        <Button type="submit" className="modal-content__save-button">
          {t("general.save")}
        </Button>
      </div>
    </form>
  );
};

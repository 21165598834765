import { Box } from "@mui/material";
import styled from "styled-components";

export const ClassStyle = styled(Box)`
  .integrations_container {
    width: 100%;
    padding: 16px;
    background-color: ${({ theme }) => theme.background.info};

    .parent_box {
      background-color: ${({ theme }) => theme.background.main};
      border-radius: 8px;
      min-height: calc(100vh - 100px);
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .service-title {
        font-size: 18px;
        text-transform: capitalize;
        color: ${({ theme }) => theme.primary.dark};
      }
    }
  }
`;

import { Table } from "components";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useListColumns } from "../components/columns";
import { useSmsTemplateSchema } from "../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { CRUDManager, ICRUDManagerProps } from "../components/CRUD";

export const SmsTemplates = () => {
  const smsTemplateSchema = useSmsTemplateSchema();

  const [action, setAction] = useState<ICRUDManagerProps["action"]>(null);

  const { t } = useTranslation();

  const columns = useListColumns();

  const methods = useForm({
    resolver: yupResolver(smsTemplateSchema),
  });

  return (
    <FormProvider {...methods}>
      <div className="main-container">
        <Table
          title={t("settings.sms_templates.title")}
          dataUrl="/sms-templates/pagin"
          columns={columns}
          onAddButton={() => setAction({ name: "create" })}
          onDeleteColumn={(row) => setAction({ name: "delete", data: row })}
          onEditColumn={(row) => setAction({ name: "edit", data: row })}
        />

        <CRUDManager action={action} setAction={setAction} />
      </div>
    </FormProvider>
  );
};

import styled from "styled-components";

export const OfflineStyle = styled("div")`
  .offline-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    z-index: 1000;
  }

  .popup-content {
    background: ${({ theme }) => theme.background.main};
    padding: 20px;
    height: max-content;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 30px;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      h2 {
        color: ${({ theme }) => theme.primary.dark};
        font-size: 24px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.primary.dark};
      margin-top: 12px;
    }

    svg {
      path {
        fill: ${({ theme }) => theme.primary.main};
      }
      circle {
        fill: ${({ theme }) => theme.primary.dark};
      }
    }
  }
`;

import { useTranslation } from "react-i18next";
import { object, string } from "yup";

export const useSmsTemplateSchema = () => {
  const { t } = useTranslation();

  const smsTemplateSchema = object().shape({
    title: string().required(t("general.required")),
    content: string().required(t("general.required")),
  });
  return smsTemplateSchema;
};

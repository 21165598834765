export const ReceiptTranslation = {
    uz: {
      settings: {
        receipt: {
          debt: "Qarz",
          reinstall: "Qayta o'rnatish",
          general_information: "Umumiy ma’lumotlar",
          language: "Chek tili",
          header: "Chekning sarlavhasi",
          check_print: "Checkni avtomatik chiqarish",
          logo: "Logotip",
          receipt_print: "Chekni chiqarish",
          check_type: {
            sale: "SAVDO CHEKI",
          },
          title: {
            title: "Sarlavha",
            logo: "Logotip",
            organization: "Tashkilot nomi",
            header: "Chekning sarlavhasi",
          },
          transaction: {
            cashier_name: "Kassir",
            student: "O'quvchi",
            cashbox: 'Kassa',
            payment_type: "To'lov turi",
            transaction_type: "Tranzaktsiya turi",
            sale_date: "Sana",
            amount: "To'lov",
            note: "Izoh",
          },
          product_info: {
            title: "Mahsulotni qo'shimcha ma'lumotlari",
            discount: "Chegirma",
            included_tax: "Shu jumladan",
            added_tax: "Bundan tashqari",
            sku: "SKU",
            barcode: "Shtrix kod",
            note: "Izoh",
          },
          cashback: {
            title: "Keshbek",
            initial: "Bor edi",
            added: "Jami qo'shildi",
            subtracted: "Olib qolindi",
            left: "Qoldiq",
          },
          balance: {
            title: "Balans",
            initial: "Bor edi",
            added: "Jami qo'shildi",
            subtracted: "Olib qolindi",
            left: "Qoldiq",
          },
          everything: "Hammasi",
          apply_organizations: "Boshqa tashkilotlarga ham qo'llash",
          organizations: "Tashkilotlar",
          total_discount: "Jami chegirma",
          total_included_tax: "Jami kiritilgan soliq",
          total_added_task: "Jami qo'shilgan soliq",
          total_tax: "Jami soliq",
          paid: "To'landi",
          change: "Qaytim",
          installment_plan: "Muddatli to'lov",
          cashback_bonus: "Keshbek bonuslar",
          bonuses: "Bonuslar",
          balances: "Balans",
          footer: "Chekning pastgi qismi",
        },
      },
    },

    ru: {
      settings: {
        receipt: {
          debt: "Долг",
          reinstall: "Переустановка",
          general_information: "Общая информация",
          language: "Язык",
          header: "Заголовок чека",
          hello: 'privet',
          check_print: "Автоматическая печать чека",
          logo: "Логотип",
          receipt_print: "Печать чека",
          check_type: {
            sale: "ЧЕК ПРОДАЖИ",
          },
          title: {
            title: "Заголовок",
            logo: "Логотип",
            organization: "Наименование организации",
            header: "Заголовок чека",
          },
          transaction: {
            cashier_name: "Кассира",
            student: "Студент",
            cashbox: 'Касса',
            payment_type: "Тип оплаты",
            transaction_type: "Тип транзакции",
            sale_date: "Дата",
            amount: "Оплата",
            note: "Комментарий",
          },
          product_info: {
            title: "Дополнительные сведения о товаре",
            discount: "Скидка",
            included_tax: "Включая налог",
            added_tax: "Дополнительно",
            sku: "Артикул",
            barcode: "Штрих-код",
            note: "Комментария",
          },
          cashback: {
            title: "Кешбек",
            initial: "Было",
            added: "Итого добавлено",
            subtracted: "Убрано",
            left: "Осталось",
          },
          balance: {
            title: "Баланс",
            initial: "Было",
            added: "Итого добавлено",
            subtracted: "Убрано",
            left: "Осталось",
          },
          everything: "Все",
          apply_organizations: "Применить и для других организаций",
          organizations: "Организации",
          total_discount: "Итого скидка",
          total_included_tax: "Итого включенный налог",
          total_added_task: "Итого добавленный налог",
          total_tax: "Итого налог",
          paid: "Оплачено",
          change: "Сдача",
          installment_plan: "План рассрочки",
          cashback_bonus: "Бонусы кешбека",
          bonuses: "Бонусы",
          balances: "Баланс",
          footer: "Нижняя часть чека",
        },
      },
    },
    en: {
      settings: {
        receipt: {
          debt: "Debt",
          reinstall: "Reinstall",
          general_information: "General Information",
          language: "Receipt Language",
          header: "Receipt Header",
          check_print: "Automatic Receipt Printing",
          logo: "Logo",
          hello: 'hello',
          receipt_print: "Print Receipt",
          check_type: {
            sale: "SALES RECEIPT",
          },
          title: {
            title: "Title",
            logo: "Logo",
            organization: "Organization Name",
            header: "Receipt Header",
          },
          transaction: {
            title: "Transaction Details",
            cashier_name: "Cashier",
            customer_name: "Customer",
            receipt_number: "Receipt Number",
            device_name: "Device Name",
            sale_date: "Date",
            currency: "Currency",
            note: "Note",
          },
          product_info: {
            title: "Product Additional Information",
            discount: "Discount",
            included_tax: "Including Tax",
            added_tax: "Additional Tax",
            sku: "SKU",
            barcode: "Barcode",
            note: "Note",
          },
          cashback: {
            title: "Cashback",
            initial: "Initial Amount",
            added: "Total Added",
            subtracted: "Amount Subtracted",
            left: "Balance Left",
          },
          balance: {
            title: "Balance",
            initial: "Initial Amount",
            added: "Total Added",
            subtracted: "Amount Subtracted",
            left: "Balance Left",
          },
          everything: "Everything",
          apply_organizations: "Apply to Other Organizations",
          organizations: "Organizations",
          total_discount: "Total Discount",
          total_included_tax: "Total Included Tax",
          total_added_task: "Total Added Tax",
          total_tax: "Total Tax",
          paid: "Paid",
          change: "Change",
          installment_plan: "Installment Plan",
          cashback_bonus: "Cashback Bonuses",
          bonuses: "Bonuses",
          balances: "Balances",
          footer: "Receipt Footer",
        },
      },
    },
  };
  
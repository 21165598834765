export function saveToSessionStorage(key: string, value: string): void {
  // Save the stringified value under the specified key
  if (value && typeof value !== "object") {
    sessionStorage.setItem(key, value);
  }
}

export function getFromSessionStorage(key: string): string | null {
  // Retrieve the value associated with the specified key
  return sessionStorage.getItem(key);
}

// Define a function to remove data from session storage
export function removeFromSessionStorage(key: string): void {
  // Remove the value associated with the specified key
  sessionStorage.removeItem(key);
}

import styled from "styled-components";

export const LoginStyled = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  .login-right {
    position: relative;
    width: 35%;
    height: 100%;
    background-color: ${({ theme }) => theme.background.main};
    overflow: hidden;

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      min-width: 335px;
      .text-center {
        img {
          width: 45px;
          height: 45px;
        }
      }

      .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        border: none !important;
      }

      p {
        font-size: 15px;
        color: ${({ theme }) => theme.primary.dark};
        font-weight: 500;
      }

      main {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      button {
        width: 100%;
        height: 56px;
      }
      .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        background-color: #f5f5f5;
        border-radius: 12px;
        border: none;
      }
      .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
        height: 42px;
        width: 42px;
      }
      .logo_icon {
        svg {
          width: 40px;
          height: 40px;
          path {
            stroke: ${({ theme }) => theme.primary.main};
          }
        }
      }
    }

    .loginleft1 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .loginleft2 {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .loginleft3 {
      position: absolute;
      right: -450px;
      bottom: 0;
    }
  }
  .login-left {
    position: relative;
    width: 65%;
    height: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.background.info};

    .school_icon {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 70px;
      position: relative;

      svg {
        transform: scale(7);
        path {
          color: ${({ theme }) => theme.primary.main};
        }
      }
      h2 {
        position: absolute;
        bottom: 80px;
        font-size: 44px;
        color: ${({ theme }) => theme.primary.main};
      }
    }

    .login-text-first {
      position: absolute;
      bottom: 16px;
      left: 24px;
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.secondary.main};
    }
    .login-text-second {
      position: absolute;
      bottom: 16px;
      right: 24px;
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.secondary.main};
    }
  }

  @media screen and (max-width: 1100px) {
    .login-left {
      width: 50%;
    }
    .login-right {
      width: 50%;
    }
  }
  @media screen and (max-width: 880px) {
    .login-left {
      display: none;
    }
    .login-right {
      width: 100%;
    }
  }
`;

import { Select } from "@mui/material";
import styled from "styled-components";

export const SelectStyled = styled(Select)<any>`
  height: 38px;
  .MuiSelect-select {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.background.disabled : theme.background.info};
    padding: 2px 12px;
    height: 38px !important;
    border-radius: 12px;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.background.info};
    line-height: 38px;
    color: ${({ theme }) => theme.primary.dark} !important;

    &:focus {
      border-radius: 12px;
      border: 2px solid ${({ theme }) => theme.background.primary};
    }
  }
  & ~ .text-error {
    position: absolute;
    bottom: -20px;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.primary.dark} !important;
  }
`;

export const SelectLableStyled = styled.div<any>`
  display: flex;
  label {
    color: ${({ theme, disabled }) =>
      disabled ? theme.primary.disabled : theme.primary.dark};
    font-weight: 500;
    font-size: 14px;
    display: block;
    margin-bottom: 4px;
  }

  .error {
    color: red;
  }
`;

import styled from "styled-components";

export const AutoCompleteStyled = styled.div<any>`
  //! @dangerous qolgan autocompletelar ham stili shunda
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.primary.disabled : theme.primary.dark};
    display: block;
    margin-bottom: 4px !important;
    span {
      color: red;
    }
  }

  .MuiInputBase-root {
    padding: 0 60px 0 4px !important;
    height: ${({ multiple }) => (multiple ? "auto" : "42px")} !important;
    min-width: 180px;
    max-width: 100%;
    background-color: ${({ theme }) => theme.background.info};
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;

    .MuiOutlinedInput-input {
      padding-left: 10px !important;
      color: ${({ theme }) => theme.primary.dark};
    }
  }

  h6.text-error {
    position: absolute;
    bottom: -18px;
  }
  position: relative;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.primary.dark};
  }

  .MuiOutlinedInput-root {
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.error.primary : theme.background.info} !important;
    transition: all 0.2s ease;

    &:hover {
      border: 2px solid
        ${({ theme, error }) =>
          error ? theme.error.primary : theme.background.primary} !important;
    }
    &:focus {
      border: 2px solid ${({ theme }) => theme.background.primary} !important;
    }
    &.Mui-disabled {
      background-color: ${({ theme }) => theme.background.disabled};
    }
  }
  .MuiChip-root {
    width: ${({ multiple }) => (multiple ? "fit-content" : "")};
    color: ${({ theme }) => theme.primary.dark};
    background-color: ${({ theme }) => theme.background.main};
  }

  svg {
    path {
      fill: ${({ theme }) => theme.primary.dark};
    }
  }
`;

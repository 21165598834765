import { Dialog } from "@mui/material";
import styled from "styled-components";

const StyledModal = styled(Dialog)`
  .MuiPaper-root {
    min-height: 550px;
    min-width: 1000px;
    height: 550px;
    width: 1000px;
    flex-wrap: wrap;
    .modal-sidebar {
      display: inline-block;
      background-color: ${({ theme }) => theme.background.info};
      width: 33%;
      height: 100%;
      padding: 32px;
      .service-name {
        font-size: 18px;
        text-transform: capitalize;
        margin: 16px 0 10px 0;
      }

      .button-wrapper {
        .card-button {
          &__installed {
            background-color: ${({ theme }) => theme.background.info};
            border: 1px solid ${({ theme }) => theme.success.main};
            cursor: auto;
          }
          &__install {
            color: ${({ theme }) => theme.secondary.main};
          }
        }
      }
      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.background.main};

        img {
          height: 100px;
          width: 135px;
          object-fit: contain;
        }
      }
    }
    .modal-content {
      width: 66%;
      display: inline-block;
      height: 100%;
      padding: 30px;

      &__action-button-wrapper {
        display: flex;
        justify-content: end;
        .card-button {
          background-color: ${({ theme }) => theme.background.info};
          border: 1px solid ${({ theme }) => theme.background.info};
          color: ${({ theme }) => theme.primary.dark};
        }
      }

      &__edit-title {
        font-size: 18px;
        margin-bottom: 32px;
      }
      &__info {
        span {
          display: inline-block;
        }
        .title {
          font-weight: 100;
          margin-bottom: 8px;
        }
        .item {
          margin-left: 20px;
          margin-bottom: 8px;
        }
      }
      &__button-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
      &__cancel-button {
        color: ${({ theme }) => theme.primary.dark};
      }
      &__save-button {
        color: ${({ theme }) => theme.background.main};
        background-color: ${({ theme }) => theme.background.primary};
      }

      .preview {
        &__image {
          width: 100%;
          height: 250px;
          object-fit: contain;
        }
      }
    }
  }
`;

export default StyledModal;

import { useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type QueryParamsType = Record<string, string>;

const useQueryHook = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const QueryParams: QueryParamsType = useMemo(
    () => Object.fromEntries(queryParams.entries()),
    [queryParams]
  );

  const stringify = useCallback(
    (
      obj: Record<string, string | number>,
      params?: string | string[][]
    ): string => {
      const searchParams = new URLSearchParams(params);
      Object.entries(obj).forEach(([key, value]) => {
        searchParams.set(key, String(value));
      });
      return searchParams.toString();
    },
    []
  );

  const addQueryParams = useCallback(
    (params: Record<string, string | number | undefined> = {}): void => {
      const newParams = new URLSearchParams(queryParams);
      Object.entries(params).forEach(([key, value]) => {
        newParams.set(key, String(value));
      });
      navigate(`?${newParams.toString()}`);
    },
    [queryParams, navigate]
  );

  const mergeQueryParams = useCallback(
    (
      params: Record<string, string | number | undefined> = {},
      replace = false
    ): void => {
      const newParams = new URLSearchParams(queryParams);
      Object.entries(params).forEach(([key, value]) => {
        newParams.set(key, String(value));
      });
      navigate(`?${newParams.toString()}`, { replace });
    },
    [queryParams, navigate]
  );

  const removeQueryParam = useCallback(
    (...key: Array<string>): void => {
      const newParams = new URLSearchParams(queryParams);
      key.forEach((item) => {
        newParams.delete(item);
      });
      navigate(`?${newParams.toString()}`);
    },
    [queryParams, navigate]
  );

  return {
    QueryParams,
    AddQueryParams: addQueryParams,
    MergeQueryParams: mergeQueryParams,
    stringify,
    navigate,
    location,
    RemoveQueryParam: removeQueryParam,
  };
};

export default useQueryHook;

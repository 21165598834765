import styled from "styled-components";

export const DateTimePickerStyled = styled.div`
  .sc-cUEOzv {
    border: 2px solid green;
  }
  .MuiFormControl-root {
    height: 42px;
    border: 2px solid ${({ theme }) => theme.background.info};
    border-radius: 12px;
    background-color: ${({ theme }) => theme.background.info};

    .MuiInputBase-root {
      height: 100%;
      color: ${({ theme }) => theme.primary.dark};
    }

    .MuiButtonBase-root {
      height: 36px;
      width: 36px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      border: 2px solid ${({ theme }) => theme.background.primary} !important;
    }

    .MuiIconButton-root {
      color: ${({ theme }) => theme.primary.dark};
    }
  }
  position: relative;

  .label {
    color: ${({ theme }) => theme.primary.dark} !important;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 0;

    .text-error {
      bottom: -10;
      position: absolute;
      color: red;
    }
  }
`;

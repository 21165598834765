export const TYPES = [
  {
    _id: "monthly",
    name: "monthly",
  },
  {
    _id: "quarterly",
    name: "quarterly",
  },
  {
    _id: "yearly",
    name: "yearly",
  },
];

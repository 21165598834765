import { CommonButton } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ICreateModalProps } from "../CRUD/Create.modal";

export interface IHeaderProps extends Pick<ICreateModalProps, "setAction"> {}

export const Header: FC<IHeaderProps> = ({ setAction }) => {
  const { t } = useTranslation();

  return (
    <header className="flex justify-end mb-8">
      <CommonButton
        title={t("general.cancel")!}
        className="grey"
        key="back"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setAction(null);
        }}
        style={{ marginRight: "15px", maxWidth: "280px" }}
      />

      <CommonButton
        type="submit"
        title={t("general.save")!}
        style={{ maxWidth: "280px" }}
        className="main"
      />
    </header>
  );
};

import styled from "styled-components";

export const TimePickerStyled = styled("div")<any>`
  border: 1px solid;
  border-color: ${({ isError }) => (isError ? "red" : "grey")} !important;

  outline: none;
`;

export const TimePickerContainer = styled("div")<{ isError: boolean }>`
  max-width: 180px;
  height: 41px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background.info};

  .react-time-picker {
    width: 100%;
    height: 100%;

    .react-time-picker__wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      border: none;
      color: ${({ theme }) => theme.primary.dark} !important;

      input {
        outline: none;
        border: none;
        background-color: inherit;
      }

      .react-time-picker__clear-button {
        outline: none !important;
        border: none !important;
        cursor: pointer;
        background-color: inherit;

        svg {
          stroke: ${({ theme }) => theme.primary.dark};
        }

        &:hover {
          svg {
            stroke: ${({ theme }) => theme.background.primary};
          }
        }
      }
    }
    .react-time-picker__clock {
      display: none;
    }
  }
`;

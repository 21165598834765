import { FC } from "react";

export interface ITouchbarProps {}

export const Touchbar: FC<ITouchbarProps> = ({}) => {
  return (
    <div className="px-1 flex justify-between pb-2">
      {/* leftside */}
      <div className="w-full basis-[70%]">
        <span className="h-1 w-4 bg-bgDisabled rounded-xl inline-block"></span>
        <div className="flex gap-2 w-full">
          {/* circle */}
          <span className="inline-block h-4 w-4 rounded-full bg-bgDisabled"></span>

          <div className="flex flex-col justify-between w-[60%]">
            <span className="h-[50%] w-[70%] bg-bgDisabled inline-block"></span>
            <span className="h-[30%] w-full bg-bgDisabled inline-block"></span>
          </div>
        </div>
      </div>

      {/* rightside */}
      <div>
        <span className="h-1 w-full bg-bgDisabled rounded-xl inline-block"></span>
        <div className="flex gap-2">
          <span className="inline-block h-3 w-3 rounded-full bg-bgDisabled"></span>
          <span className="inline-block h-3 w-3 rounded-full bg-bgDisabled"></span>
        </div>
      </div>
    </div>
  );
};

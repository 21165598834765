import { AutoCompleteForm } from "components";
import { get } from "lodash";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface ISelectIntegrationProps {}

export const SelectIntegration: FC<ISelectIntegrationProps> = ({}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  return (
    <div className="mt-3">
      <AutoCompleteForm
        getLabelOption={(option) => option?.name}
        control={control}
        name="integrationId"
        label={t("LABELS.INTEGRATION")}
        optionsUrl="integrations"
        dataProp="data"
        onChange={(e) => {
          setValue("content", get(e, "content"));
        }}
        exQueryParams={{
          isInstalled: true,
          isActive: true,
          service: "sms",
        }}
      />
    </div>
  );
};

import { GridColumns } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { ITeacherWorkReport } from "../../types";
import { CustomLink } from "styles/global.style";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";
import dayjs from "dayjs";

export const useRoomColumns = (): GridColumns<ITeacherWorkReport> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const allParams = useAllQueryParams();
  const thisMonthStart = dayjs().startOf("day").toISOString();
  const thisMonthEnd = dayjs().endOf("day").toISOString();

  return [
    {
      field: t("TABLE.NAME_SURNAME"),
      renderCell(params) {
        return params.row.teacher.firstName + " " + params.row.teacher.lastName;
      },
    },
    {
      field: t("TABLE.LESSON_COUNT"),
      renderCell(params) {
        return (
          <CustomLink
            onClick={() =>
              navigate(
                `/analitics/teacher-work-reports/${get(params, "id", "")}?fromDate=${allParams?.fromDate || thisMonthStart }&toDate=${allParams?.toDate || thisMonthEnd}`
              )
            }
          >
            {params.row.totalTeacherLessonsCount}
          </CustomLink>
        );
      },
    },
    {
      field: t("TABLE.INTERACTIONS_COUNT"),
      renderCell(params) {
        return (
          <CustomLink
            onClick={() =>
              navigate(
                `/analitics/teacher-work-reports/${get(params, "id", "")}/checked?fromDate=${allParams?.fromDate || thisMonthStart }&toDate=${allParams?.toDate || thisMonthEnd}`
              )
            }
          >
            {params.row.totalInteractionsCount}
          </CustomLink>
        );
      },
    },
    {
      field: t("TABLE.HOMEWORK_COUNT"),
      renderCell(params) {
        return (
          <CustomLink
            onClick={() =>
              navigate(
                `/analitics/teacher-work-reports/${get(params, "id", "")}/hasHomework?fromDate=${allParams?.fromDate || thisMonthStart }&toDate=${allParams?.toDate || thisMonthEnd}`
              )
            }
          >
            {params.row.totalGivenHomeworkLessonsCount}
          </CustomLink>
        );
      },
    },
  ];
};

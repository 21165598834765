import { ButtonGroup } from "@mui/material";
import styled from "styled-components";

export const StyledNavbarButtonGroup = styled(ButtonGroup)`
  .MuiButton-contained {
    border: none;
    background-color: ${({ theme }) => theme.background.primary};
    color: #fff !important;
    &:hover {
      background-color: ${({ theme }) => theme.background.primary};
    }
  }
  .MuiButton-outlined {
    color: ${({ theme }) => theme.background.primary};
    border: 1px solid ${({ theme }) => theme.background.primary};
    &:hover {
      border: 1px solid ${({ theme }) => theme.background.primary};
    }
  }
`;

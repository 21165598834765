import { TextInput } from "components";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface ISelectTemplatesProps {}

export const SelectTemplates: FC<ISelectTemplatesProps> = ({}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <div className="mt-3">
      <TextInput
        control={control}
        name="content"
        label={t("general.content")!}
        placeholder={t("general.content")!}
        multiline
        rows={5}
      />
    </div>
  );
};

import { useTranslation } from "react-i18next";

interface ISmsVariable {
  keyword: string;
  value: string;
}
export const useGetSmsVariables = () => {
  const { t } = useTranslation();
  const smsVariables: Array<ISmsVariable> = [
    { value: "student_name", keyword: t("general.student_name") },
    { value: "class_name", keyword: t("general.class_name") },
    { value: "parent_name", keyword: t("general.parent_name") },
    { value: "birthday", keyword: t("general.birthday") },
    { value: "contract_number", keyword: t("general.contract_number") },
    { value: "contract_date", keyword: t("general.contract_date") },
    { value: "state", keyword: t("general.state") },
    { value: "payment_type", keyword: t("general.payment_type") },
    { value: "address", keyword: t("general.address") },
    { value: "subscription", keyword: t("general.subscription") },
    { value: "next_charge_date", keyword: t("general.next_charge_date") },
    {
      value: "final_charge_date_by_subscription",
      keyword: t("general.final_charge_date_by_subscription"),
    },
    { value: "last_charged_at", keyword: t("general.last_charged_at") },
    {
      value: "subscriptoin_stared_charging_at",
      keyword: t("general.subscriptoin_stared_charging_at"),
    },
    { value: "last_paymentAt", keyword: t("general.last_paymentAt") },
  ];

  return smsVariables;
};

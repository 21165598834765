import { Loading } from "components";
import UseNetwork from "components/elements/OfflinePopup/useNetwork";
import { useApi } from "hooks/useApi/useApiHooks";
import { Outlet, useNavigate } from "react-router-dom";
import {
  ILoginData,
  setLoginData,
  setThemeState,
} from "store/reducers/LoginSlice";
import { useAppDispatch } from "store/storeHooks";

const AuthUser = () => {
  const dis = useAppDispatch();
  const hasToken = !!localStorage.getItem("token");
  const navigate = useNavigate();
  const isOnline = UseNetwork();

  const { isLoading, isFetching } = useApi<ILoginData>(
    "auth",
    {},
    {
      enabled: hasToken && isOnline,
      cacheTime: 0,
      staleTime: Infinity,
      onSuccess(data) {
        dis(setLoginData(data.data));
        const theme = localStorage.getItem("theme");
        dis(setThemeState(theme || "light"));

        localStorage.setItem("employeeId", data.data._id);
        if (!localStorage.getItem("i18nextLng")) {
          localStorage.setItem("i18nextLng", "uz");
        }
        if (!localStorage.getItem("schedule_show")) {
          localStorage.setItem("schedule_show", "class");
        }
        navigate("/schedule");
      },
      onError() {
        // window.location.replace("/login");
      },
    }
  );
  if (isLoading || isFetching) {
    return <Loading />;
  }
  return <Outlet />;
};

export default AuthUser;

import { Grid } from "@mui/material";
import { AutoCompleteForm } from "components";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface IClassesProps {}

export const Classes: FC<IClassesProps> = ({}) => {
  const { control, resetField } = useFormContext();
  const { t } = useTranslation();

  const type = useWatch({ control, name: "type" });
  const quarter = useWatch({ control, name: "quarterId" });
  const month = useWatch({ control, name: "month_and_year" });

  if (type === "quarterly" && !quarter) return null;
  if (!month) return null;

  return (
    <Grid item xs={3} md={2.4}>
      <AutoCompleteForm
        control={control}
        name="classIds"
        onChange={() => {
          resetField("subjectIds");
        }}
        label={t("LABELS.CLASS")!}
        optionsUrl="class/pagin"
        dataProp="data.data"
        clearable={false}
        getLabelOption={(option) => `${option?.grade} - ${option?.letter}`}
        exQueryParams={{
          limit: 200,
          page: 1,
        }}
        multiple
      />
    </Grid>
  );
};

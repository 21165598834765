import { FC } from "react";
import { Type } from "./Type";
import { Year } from "./Year";
import { Quarter } from "./Quarter";
import { Classes } from "./Classes";
import { Subjects } from "./Subjects";
import { Grid } from "@mui/material";

export interface IFiltersProps {}

export const Filters: FC<IFiltersProps> = ({}) => {
  return (
    <div className="bg-bgMain p-4 rounded-lg flex flex-wrap">
      <Grid container spacing={"20px"}>
        <Type />
        <Year />
        <Quarter />
        <Classes />
        <Subjects />
      </Grid>
    </div>
  );
};

import { DoneIcon, ImportIcon, UploadFile } from "assets/svgs";
import { CommonButton, CommonModal, Loading } from "components";
import { useRef, useState } from "react";
import { ImportStudentStyle } from "../container/Students.style";
import { fetchAndDownloadData } from "./fetchAndDownload";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import { get } from "lodash";
import { Spin } from "antd";
import { toast } from "react-toastify";
import { reRenderTable } from "components/elements/table/reducer/table.slice";
import { useAppDispatch } from "store/storeHooks";
import { Trans, useTranslation } from "react-i18next";

const ImportModal = () => {
  const [open, setOpen] = useState<string>("");
  const [errors, setErrors] = useState<Record<string, any>[]>([]);
  const [uploadedFilePath, setUploadedFilePath] = useState<string>("");
  const fileRef = useRef<any>(null);
  const dis = useAppDispatch();
  const { t } = useTranslation();

  const { mutate: confirmStudent, status: confirmStatus } = useApiMutation(
    "/import/students/confirm",
    "post",
    {
      onSuccess() {
        setOpen("");
        toast.success(t("toast_messages.success"));
        dis(reRenderTable(true));
        setUploadedFilePath("");
        setErrors([]);
      },
    }
  );

  const { mutate, status: uploadStatus } = useApiMutation(
    "/import/students",
    "post",
    {
      onSuccess(data) {
        setUploadedFilePath(get(data, "data.uploadedFilePath", ""));
        setErrors([]);
        fileRef.current.value = "";
      },
      onError(data) {
        //@ts-ignore
        setErrors(get(data, "message.errors", []));
      },
    }
  );

  const uploadFile = (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    if (formData) {
      mutate(formData);
    }
  };

  return (
    <>
      <CommonButton
        title="Import"
        className="import"
        startIcon={<ImportIcon />}
        onClick={() => setOpen("open-import")}
      />
      <CommonModal open={!!open} setOpen={setOpen} canClose={false} width={800}>
        <ImportStudentStyle>
          <h2 className="text-center">{t("general.import")}</h2>
          <h4 className="my-3">
            <Trans
              i18nKey="general.import_student"
              components={[
                <span
                  className="text-main cursor-pointer"
                  onClick={() => {
                    fetchAndDownloadData(
                      "/import/students/shablon",
                      "students.csv"
                    );
                  }}
                ></span>,
              ]}
            />
          </h4>

          <div className="py-3 mb-3 import-student-box">
            <label htmlFor="import-student">
              {uploadedFilePath ? <DoneIcon /> : <UploadFile />}
              <span>
                {uploadedFilePath
                  ? t("general.uploaded_file")
                  : t("general.upload_file")}
              </span>
              <input
                type="file"
                className="upload-input"
                id="import-student"
                onChange={uploadFile}
                accept="file/*"
                disabled={!!uploadedFilePath || uploadStatus === "loading"}
                ref={fileRef}
              />
              {uploadStatus === "loading" && (
                <div className="d-flex align-items-center">
                  <Spin />
                </div>
              )}
            </label>
          </div>

          {!!errors.length && (
            <div className="errors-upload">
              <ul className="content">
                {errors?.map((error: any) => {
                  return (
                    <li>
                      {error.row} - {t("general.row")}
                      <ul>
                        {error?.error.map((err: any) => {
                          return (
                            <li>
                              {err.property}: {err.constraints}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="d-flex justify-content-end align-items-center gap-3">
            <CommonButton
              title={t("general.back")!}
              onClick={() => {
                setOpen("");
                setUploadedFilePath("");
                setErrors([]);
              }}
              disabled={
                confirmStatus === "loading" || uploadStatus === "loading"
              }
            />
            <CommonButton
              title={t("general.save")!}
              className="main"
              onClick={() => confirmStudent({ uploadedFilePath })}
              status={confirmStatus}
              disabled={confirmStatus === "loading" || !uploadedFilePath}
            />
          </div>
        </ImportStudentStyle>
      </CommonModal>

      {uploadStatus === "loading" && <Loading />}
    </>
  );
};

export default ImportModal;

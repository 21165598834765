import React from "react";

const NavSelectIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_177_5571)">
          <path
            d="M2.25 0C1.00781 0 0 1.00781 0 2.25V21.75C0 22.9922 1.00781 24 2.25 24H6.75V20.25C6.75 19.0078 7.75781 18 9 18C10.2422 18 11.25 19.0078 11.25 20.25V24H15.75C16.9922 24 18 22.9922 18 21.75V2.25C18 1.00781 16.9922 0 15.75 0H2.25ZM3 11.25C3 10.8375 3.3375 10.5 3.75 10.5H5.25C5.6625 10.5 6 10.8375 6 11.25V12.75C6 13.1625 5.6625 13.5 5.25 13.5H3.75C3.3375 13.5 3 13.1625 3 12.75V11.25ZM8.25 10.5H9.75C10.1625 10.5 10.5 10.8375 10.5 11.25V12.75C10.5 13.1625 10.1625 13.5 9.75 13.5H8.25C7.8375 13.5 7.5 13.1625 7.5 12.75V11.25C7.5 10.8375 7.8375 10.5 8.25 10.5ZM12 11.25C12 10.8375 12.3375 10.5 12.75 10.5H14.25C14.6625 10.5 15 10.8375 15 11.25V12.75C15 13.1625 14.6625 13.5 14.25 13.5H12.75C12.3375 13.5 12 13.1625 12 12.75V11.25ZM3.75 4.5H5.25C5.6625 4.5 6 4.8375 6 5.25V6.75C6 7.1625 5.6625 7.5 5.25 7.5H3.75C3.3375 7.5 3 7.1625 3 6.75V5.25C3 4.8375 3.3375 4.5 3.75 4.5ZM7.5 5.25C7.5 4.8375 7.8375 4.5 8.25 4.5H9.75C10.1625 4.5 10.5 4.8375 10.5 5.25V6.75C10.5 7.1625 10.1625 7.5 9.75 7.5H8.25C7.8375 7.5 7.5 7.1625 7.5 6.75V5.25ZM12.75 4.5H14.25C14.6625 4.5 15 4.8375 15 5.25V6.75C15 7.1625 14.6625 7.5 14.25 7.5H12.75C12.3375 7.5 12 7.1625 12 6.75V5.25C12 4.8375 12.3375 4.5 12.75 4.5Z"
            fill="#2A3042"
          />
        </g>
        <defs>
          <clipPath id="clip0_177_5571">
            <rect width="18" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default NavSelectIcon;

import { EmptyTable } from "assets/svgs";
import React from "react";
import { useTranslation } from "react-i18next";

const NoDataFound = () => {
  const { t } = useTranslation();

  return (
    <div className="no-data-found">
      <EmptyTable />
      <h2>{t("PAGES_TITLE.PAGES_NOT_FOUND")}</h2>
    </div>
  );
};

export default NoDataFound;

import { Spin } from "antd";
import { LoaderStyled } from "./Loader.style";
const Loading = () => {
  return (
    <LoaderStyled>
      <Spin  />
    </LoaderStyled>
  );
};

export default Loading;

import { Drawer } from "@mui/material";
import styled from "styled-components";

export const FormDrawerStyled = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: ${({ theme }) => theme.background.main};
  }
  .form_drawer_container {
    height: calc(100vh - 80px) !important;
    overflow-y: auto !important;
    background-color: ${({ theme }) => theme.background.main};

    .title {
      font-size: 24px;
      font-weight: 600;
      color: ${({ theme }) => theme.primary.dark};
    }

    &.active {
      height: 100vh !important;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.scrollbar.thumb};
      border-radius: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.scrollbar.track};
      border-radius: 12px;
    }

    .form_drawer_header {
      width: 100%;
      padding: 34px 24px 24px 24px;
      background-color: ${({ theme }) => theme.background.main};

      .form_drawer_header_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .form_drawer_content {
      height: calc(100vh - 98px - 80px) !important;
      padding: 0 24px;
      background-color: ${({ theme }) => theme.background.main};
    }
  }

  .form_drawer_bottom {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.background.main} !important;
    .form_drawer_bottom_content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;

      .MuiButtonBase-root {
        width: 100% !important;
      }
    }
  }

  .close_btn {
    svg {
      path {
        fill: #999999;
      }
    }
  }
  .deleteBtn {
    background-color: #f05252 !important;
    color: #ffffff !important;
  }
`;

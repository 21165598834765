import { IconButton } from "@mui/material";
import styled from "styled-components";
interface IValueProps {
  value: boolean;
}
export const SidebarContainer = styled.div<IValueProps>`
  z-index: 99;
  .sidebar-item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    padding-left: ${(props) => (props.value ? "27px" : "21px")};
    transition: all 0.3s ease;
    height: ${(props) => (props.value ? "auto" : "50px")};
    color: ${({ theme }) => theme.primary.dark};
    font-size: 14px;
    font-weight: 400;

    .iconActive {
      padding-left: 15px;
    }

    .unical-logo {
      width: 1200px !important;
    }

    svg {
      transition: all 0.3s ease;
      path {
        stroke: ${({ theme }) => theme.primary.main};
      }
    }

    &:hover {
      cursor: pointer !important;
      background-color: ${({ theme }) => theme.hover.main} !important;
      color: ${({ theme }) => theme.primary.dark} !important;
    }
  }
  .sidebar-item-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    color: #666687;
    padding-left: ${(props) => (props.value ? "27px" : "21px")};
    font-size: 14px;
    font-weight: 400;
    /* margin-bottom: 0.5rem; */
    transition: all 0.3s ease;
    color: ${({ theme }) => theme.primary.dark};
    height: ${(props) => (props.value ? "auto" : "50px")};

    &.active {
      background-color: ${({ theme }) => theme.hover.main};
    }

    .boxsOfChild {
      display: flex;
      align-items: center;

      .sidebar_hovered_box {
        width: 400px;
        padding: 10px;
      }

      .iconActive {
        padding-left: 15px;
      }
      .boxOfTexts {
        margin-left: 10px;

        &.active {
          display: none;
        }
      }
    }
    svg {
      transition: all 0.3s ease;
      path {
        stroke: ${({ theme }) => theme.primary.main};
      }
    }
    .upAndDownIcon {
      margin-right: 10px;
      path {
        stroke: none;
        fill: ${({ theme }) => theme.primary.main};
      }
    }

    &:hover {
      cursor: pointer !important;
      background-color: ${({ theme }) => theme.hover.main};
      color: ${({ theme }) => theme.primary.dark};
    }
  }

  .sideBar-active {
    cursor: pointer !important;
    background-color: ${({ theme }) => theme.hover.main};

    span {
      color: ${({ theme }) => theme.background.primary} !important;
    }

    svg {
      path {
        stroke: ${({ theme }) => theme.primary.main};
      }
    }
  }
  position: relative;
  overflow: hidden;
  .MuiListItemIcon-root {
    min-width: ${(props) => (props.value ? "30px" : "70px")};
  }
  .MuiListItem-button {
    padding-left: 10px !important;

    a {
      color: #666687;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      /* color: #666687; */
    }

    white-space: nowrap;
  }
  .sidebar-content {
    background-color: ${({ theme }) => theme.sidebar.main};
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    bottom: 0;
    border-right: 1px solid ${({ theme }) => theme.border.main};
  }

  .sidebar-top {
    height: 72px;
    background-color: ${({ theme }) => theme.background.main};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 12px;
    box-sizing: border-box;
    position: relative;

    &.active {
      height: 72px;
    }
  }
  .sidebar_arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.border.main};
    bottom: -15px;
    right: -15px;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 99999;
    background-color: ${({ theme }) => theme.background.main};

    svg {
      path {
        fill: ${({ theme }) => theme.primary.dark};
      }
    }

    &.active {
      transform: rotateY(180deg);
    }
    &:hover {
      background-color: ${({ theme }) => theme.background.main};
    }
  }
  .sidebar-top-item {
    width: ${(props) => (props.value ? "auto" : "100%")};
    background-color: ${({ theme }) => theme.hover.main};
    border-radius: 10px;
    padding: 6px;
    display: flex;
    height: 100%;
    width: 100%;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      background-color: ${({ theme }) => theme.hover.main};
    }

    .school_head {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 0 10px;

      svg {
        transform: scale(1.2);
        path {
          fill: ${({ theme }) => theme.primary.main};
        }
      }

      p {
        font-size: 14px;
        color: ${({ theme }) => theme.primary.dark};

        span {
          color: #0086ff;
          font-size: 12px;
        }
        .username {
          display: inline-block;
          width: 100%;
          max-width: 140px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          color: ${({ theme }) => theme.primary.dark};
        }
      }
    }

    .school_head-active {
      align-items: center;
      justify-content: center;
      margin-left: 20px;

      svg {
        transform: scale(1.2);
        path {
          fill: ${({ theme }) => theme.primary.main};
        }
      }
    }
  }

  .sidebar-main {
    overflow-x: hidden;
    overflow-y: auto !important;
    height: calc(100vh - 72px - 20px);
    display: grid;
    grid-template-columns: 1fr;

    /* width */
    ::-webkit-scrollbar {
      width: 4px;
      transition: 0.2s;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.scrollbar.track};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.scrollbar.thumb};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${({ theme }) => theme.scrollbar.thumb};
    }
  }

  .asosiy {
    margin-bottom: 10px;
    h3 {
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
      color: ${({ theme }) => theme.primary.dark};
    }
  }

  .sidebar-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 48px;
    border-top: 1px solid ${({ theme }) => theme.border.main};
    background-color: ${({ theme }) => theme.background.main};
    display: flex;
    justify-content: right;
    align-items: right;
    padding: 1rem;
  }
  .MuiTypography-root {
    color: ${({ theme }) => theme.primary.dark};
    font-size: 16px;
    font-weight: 500;
  }

  .sidebar_footer {
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${({ theme }) => theme.border.main};

    .sidebar_foot_item {
      position: relative;
      width: 100%;
      height: 52px;
      color: ${({ theme }) => theme.primary.dark};

      &:hover {
        background-color: ${({ theme }) => theme.hover.main};
      }
      .icon_foot {
        height: 52px !important;
        font-size: 16px;
        padding: 0 27px;
        color: ${({ theme }) => theme.primary.dark};
        display: flex;
        align-items: center;
        gap: 18px;
        cursor: pointer;
      }
    }
  }
`;

export const HoveredItems = styled.div`
  width: 175px;

  ul {
    /* margin-bottom: 5px; */
    &:nth-child(1) {
      margin-top: 5px;
    }
  }
  .hovered_title {
    font-weight: 600;
    font-size: 13px;
    color: ${({ theme }) => theme.primary.dark};

    svg {
      path {
        stroke: ${({ theme }) => theme.primary.dark} !important;
      }
    }
  }
  li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    font-weight: 500;
    color: ${({ theme }) => theme.primary.dark} !important;
    padding: 10px 2px;
    cursor: pointer;

    svg {
      circle {
        fill: ${({ theme }) => theme.primary.dark} !important;
      }
    }

    &.sideBarHovered-active {
      background: ${({ theme }) => theme.hover.secondary};
      border-radius: 10px;
      color: ${({ theme }) => theme.primary.main} !important;
    }
    &:hover {
      background: ${({ theme }) => theme.hover.main};
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    gap: 10px;
    height: 34px;
    border-bottom: 1px solid ${({ theme }) => theme.border.main};

    span {
      margin-top: 2px;
      img {
        width: 16px;
      }
    }
  }
`;

export const SettingDrawerStyle = styled.div`
  width: 360px;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.background.main};

  .top {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;

      h2 {
        font-size: 33px;
        font-weight: 700;
        color: ${({ theme }) => theme.primary.dark};
      }
    }

    .section {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 14px;

      .head {
        display: flex;
        align-items: center;
        gap: 12px;

        svg {
          width: 40px;
          height: 40px;

          path {
            fill: ${({ theme }) => theme.primary.main};
          }
        }

        p {
          font-size: 16px;
          color: ${({ theme }) => theme.primary.dark};
          cursor: pointer;

          span {
            color: ${({ theme }) => theme.primary.dark};
            font-size: 14px;
          }

          &:hover {
            color: #0086ff;
          }
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 52px;
        height: 24px;
      }

      /* Hide the default checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider (the rounded part of the switch) */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d9d8dd;
        transition: 0.4s;
        border-radius: 28px;
      }

      /* Rounded corners of slider */
      .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }

      /* When the checkbox is checked, change the background color of the slider */
      .switch input:checked + .slider {
        background-color: ${({ theme }) => theme.background.primary};
      }

      /* When the checkbox is checked, move the knob to the right */
      .switch input:checked + .slider:before {
        transform: translateX(28px);
      }

      /* Rounded corners of slider for dark mode */
      .slider:before {
        background-color: #f2f2f2;
      }

      /* Change knob color for dark mode */
      .switch input:checked + .slider:before {
        background-color: ${({ theme }) => theme.background.main};
      }
      .theme_title {
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        color: ${({ theme }) => theme.primary.dark};
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .theme_label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
`;

export const LanguageBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 12px;

  button {
    width: 100%;
    height: 42px;
    border: none;
    outline: none;
    border-radius: 12px;
    color: ${({ theme }) => theme.background.primary};
    background-color: ${({ theme }) => theme.hover.main};
    font-size: 14px;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.hover.main};

    &:hover {
      border: 1px solid ${({ theme }) => theme.border.main};
    }

    &.active {
      background-color: ${({ theme }) => theme.background.primary};
      color: #fff;
    }
  }
`;

export const NotificationButton = styled(IconButton)`
  svg {
    path {
      fill: ${({ theme }) => theme.primary.main};
    }
  }
`;

export const NotificationContainer = styled("div")`
  .ant-popover-inner {
    padding: 0;
    margin: 0;
  }
`;
export const NotificationContent = styled("div")`
  width: 400px;
  min-height: 160px;
  max-height: 420px;
  overflow: auto;
  padding: 0 16px 16px 16px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background.main};

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbar.thumb};
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.scrollbar.track};
    border-radius: 12px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
    background-color: ${({ theme }) => theme.background.main};
    position: sticky;
    top: 0;
    z-index: 99;

    h2 {
      font-size: 16px;
      color: ${({ theme }) => theme.primary.dark};
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item {
      display: flex;
      gap: 6px;
      flex-direction: column;
      background-color: green;
      padding: 12px;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.background.body};

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          display: flex;
          gap: 7px;
          font-size: 14px;
          color: ${({ theme }) => theme.primary.dark};
        }
        span {
          display: flex;
          align-items: center;
          gap: 6px;
          color: #666;
        }
      }

      .message {
        color: ${({ theme }) => theme.primary.dark};
      }

      .foot {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .readable_box {
          width: 10px;
          height: 10px;
          background-color: red;
          border-radius: 10px;
          display: inline-block;
        }

        .time {
          font-size: 13px;
          color: ${({ theme }) => theme.primary.secondary};
        }
      }
    }
  }
`;

import { ILoginData } from "store/reducers/LoginSlice";
import { useAppSelector } from "store/storeHooks";

export type IRoles_ = ILoginData["branchEmployees"][number]["role"];

export const useRoleManager = () => {
  const { branchEmployees, isBoss } = useAppSelector(
    (store) => store.LoginState.loginData
  );

  // I used "_id" prop for public roles and to home page
  const hasAccess = (roleName: keyof IRoles_ = "_id") => {
    if (isBoss) {
      return true;
    }
    if (roleName === "_id") {
      return true;
    }
    try {
      const currentRole = branchEmployees.find(
        (fRole) => fRole.branchId === branchEmployees[0].branchId
      )!;
      const checkAccess = currentRole?.role[roleName];
      return !!checkAccess;
    } catch (err) {
      return false;
    }
  };
  return hasAccess;
};

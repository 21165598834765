import { FC } from "react";
import styled from "styled-components";
import { Cancel as CancelIcon } from "@material-ui/icons";

export interface ICancelProps {
  color?: string;
}

const StyledCancelIcon = styled(CancelIcon)<ICancelProps>`
  color: ${(props) => props.color || "red"};
`;

export const Cancel: FC<ICancelProps> = ({ color, ...props }) => {
  return <StyledCancelIcon color={color as any} {...props} />;
};

import { Box } from "@mui/material";
import styled from "styled-components";

export const StudentStyle = styled.div``;
export const ImportStudentStyle = styled.div`
  h2 {
    color: ${({ theme }) => theme.primary.dark};
  }
  h4 {
    color: ${({ theme }) => theme.primary.dark};
  }
  .import-student-box {
    width: 100%;

    label {
      width: max-content;
      min-height: max-content !important;
      display: flex;
      align-items: center;
      gap: 20px;
      border-radius: 10px;
      transition: all 0.3s ease;
      margin: 0 auto;
      padding: 12px;
      color: ${({ theme }) => theme.primary.dark};

      .upload-input {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        opacity: 0 !important;
        z-index: -1 !important;
      }

      &:hover {
        background-color: ${({ theme }) => theme.background.info};
      }
    }
  }
  .errors-upload {
    margin-bottom: 12px;
    .content {
      width: 100%;
      padding: 10px;
      background-color: ${({ theme }) => theme.background.info};
      border-radius: 6px;
      max-height: 400px;
      overflow: auto;
    }
  }
`;

export const StudentFormStyle = styled(Box)`
  header {
    padding: 24px 0;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: ${({ theme }) => theme.background.main};
    margin-bottom: 20px;

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: ${({ theme }) => theme.primary.dark};
    }
  }
  p,
  span {
    color: ${({ theme }) => theme.primary.dark};
  }
  .formContent {
    min-height: 200px;
    padding-bottom: 16px;

    /* &:last-child {
      min-height: 600px;
    } */
  }
`;

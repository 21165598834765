import { AutoCompleteForm } from "components";
import { get } from "lodash";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface ISmsTemplatesProps {}

export const SmsTemplates: FC<ISmsTemplatesProps> = ({}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  return (
    <div className="mt-3">
      <AutoCompleteForm
        getLabelOption={(option) => option?.title}
        control={control}
        name="template"
        label={t("LABELS.TEMPLATE")}
        optionsUrl="sms-templates/pagin"
        dataProp="data.data"
        onChange={(e) => {
          setValue("content", get(e, "content"));
        }}
        exQueryParams={{
          page: 1,
          limit: 200,
        }}
      />
    </div>
  );
};

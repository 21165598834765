import { CircularProgress } from "@mui/material";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "./Installment.style";

export interface IInstallButtonProps {
  id: string;
  refetch: any;
}

export const InstallButton: FC<IInstallButtonProps> = ({ id, refetch }) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useApiMutation(
    `integration/install/${id}`,
    "post"
  );

  function handleInstall() {
    mutateAsync({}).then(() => refetch());
  }

  if (isLoading)
    return (
      <StyledButton
        disabled
        startIcon={<CircularProgress color="inherit" size={20} />}
      />
    );

  return (
    <StyledButton onClick={handleInstall}>
      {t("integrations.install")}
    </StyledButton>
  );
};

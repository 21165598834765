import Autocomplete from "@mui/lab/Autocomplete";
import { TextField } from "@mui/material";
import { AutoCompleteStyled } from "components/form/AutoCompleteForm/AutoCompleteForm.style";
import { getValueFromOptions } from "components/form/AutoCompleteForm/utils/AutoComplete.utils";
import { useApi } from "hooks/useApi/useApiHooks";
import useDebounce from "hooks/useDebounce";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { IOption } from "types/form.types";

interface IAutocompleteFilter {
  filterName: string;
  options?: IOption[];
  optionsUrl?: string;
  returnOnlyId?: boolean;
  label?: string;
  placeholder?: string | number;
  disabled?: boolean;
  exQueryParams?: object;
  valueKey?: string;
  dataProp?: string;
  multiple?: boolean;
  onChange?: (options: IOption | null | undefined) => void;
  getOptionLabel?: (option: IOption) => string;
}

function AutoCompleteFilter({
  options = [], // ! must be like {_id:"value", name:"name"}
  optionsUrl = "", // 😐
  returnOnlyId = true,
  label = "",
  disabled,
  placeholder,
  filterName,
  valueKey = "_id",
  exQueryParams = {},
  dataProp = "data.data" as const, //😐
  onChange,
  getOptionLabel,
  multiple = false,
}: IAutocompleteFilter) {
  const [queryParams, setQueryParams] = useState<{ search?: string }>();
  const [search, setSearch] = useState<string>();
  const { debouncedValue: debVal, isDebouncing } = useDebounce(search, 600);
  const [value, setValue] = useState<string | undefined | null>(null);
  const { search: searchQuery } = useLocation();
  const [multipleValue, setMultipleValue] = useState<IOption<string>[]>([]);
  useEffect(() => {
    setQueryParams({
      search,
    });
  }, [debVal]);

  const { data: OptionsData, isFetching } = useApi<IOption[]>(
    optionsUrl,
    {
      ...queryParams,
      ...exQueryParams,
      page: 1,
      limit: 30,
    },
    {
      enabled: !!optionsUrl,
      suspense: false,
    }
  );

  const [__, setSearchParams] = useSearchParams();
  const allParams = useAllQueryParams();

  const { t } = useTranslation();

  useEffect(() => {
    if (allParams[filterName]) {
      if (multiple) {
        const parsedMultiValue = JSON.parse(allParams[filterName]);
        setMultipleValue(parsedMultiValue);
      } else {
        if (returnOnlyId) {
          setValue(allParams[filterName]);
        } else {
          const parsed = JSON.parse(allParams[filterName]);
          setValue(parsed);
        }
      }
    }
  }, [searchQuery]);

  return (
    <AutoCompleteStyled multiple={multiple}>
      {label && <label>{label}</label>}
      <Autocomplete
        options={(get(OptionsData, dataProp) as IOption[]) || options}
        getOptionLabel={(option: IOption) =>
          getOptionLabel
            ? `${getOptionLabel?.(option)}`
            : option?.name || option?.firstName + " " + option?.lastName || ""
        }
        multiple={multiple}
        limitTags={1}
        isOptionEqualToValue={(option: IOption, value: IOption) =>
          option[valueKey] === value[valueKey]
        }
        onChange={(e, data) => {
          // @ts-ignore
          onChange?.(data);
          if (multiple) {
            // @ts-ignore
            setMultipleValue(data);
          } else {
            // @ts-ignorec
            setValue(returnOnlyId ? data?._id! : data);
          }

          // @ts-ignore
          if (filterName && !multiple) {
            if (data) {
              setSearchParams({
                ...allParams,
                // @ts-ignore
                [filterName]: returnOnlyId ? data?._id : JSON.stringify(data),
              });
            } else {
              delete allParams[filterName];
              setSearchParams({
                ...allParams,
              });
            }
          } else {
            if (data) {
              setSearchParams({
                ...allParams,
                // @ts-ignore
                [filterName]: JSON.stringify(data),
              });
            } else {
              delete allParams[filterName];
              setSearchParams({
                ...allParams,
              });
            }
          }
        }}
        loading={isFetching || isDebouncing}
        disabled={disabled}
        sx={{
          "& fieldset": { border: "none" },
        }}
        loadingText="izlamoqda..."
        noOptionsText={!isDebouncing && "malumot topilmadi"}
        //@ts-ignore
        value={
          returnOnlyId
            ? multiple
              ? multipleValue
              : getValueFromOptions(
                  (get(OptionsData, dataProp) as IOption[]) || options,
                  value!
                )
            : value
        }
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                onChange: (e) => {
                  // @ts-ignore
                  params?.inputProps?.onChange?.(e);
                  // @ts-ignore
                  setSearch(e.target.value || "");
                },
              }}
              onBlur={() => setSearch(undefined)}
              variant="outlined"
              fullWidth
              // @ts-ignore
              placeholder={placeholder || t("LABELS.ROLE_ALL.TITLE")}
            />
          </>
        )}
      />
    </AutoCompleteStyled>
  );
}

export default AutoCompleteFilter;

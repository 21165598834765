import { Button } from "@mui/material";
import { IIntegration } from "pages/settings/integrations/constants/Integration.type";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface IViewProps {
  setMode: (state: "edit" | "view") => void;
  data: IIntegration;
}

export const View: FC<IViewProps> = ({ setMode, data }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="modal-content__action-button-wrapper">
        <Button onClick={() => setMode("edit")} className="card-button">
          {t("general.editing")}
        </Button>
      </div>

      <p className="modal-content__edit-title">{t("integrations.info")}</p>

      <div className="modal-content__info">
        {data.fields.map((item) => (
          <>
            <span className="title">{item.fieldLabel[i18n.language]}:</span>
            <span className="item">{item.fieldValue}</span>
            <br />
          </>
        ))}
      </div>
    </div>
  );
};

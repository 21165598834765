import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { StyledDateMonthPicker } from "./style";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";

interface IDateYearFilter {
  defaultValue?: Dayjs | undefined;
  clearable?: boolean;
  filterName?: string;
  type?: "year" | "month";
}
const DateYearFilter = ({
  defaultValue,
  clearable = false,
  filterName = "date",
  type = "year",
}: IDateYearFilter) => {
  const [value, setValue] = useState<Dayjs | undefined>(defaultValue);
  const [__, setSearchParams] = useSearchParams();
  const allParams = useAllQueryParams();

  const onChange = (date: any) => {
    if (date) {
      setValue(date);
      setSearchParams({
        ...allParams,
        [filterName]:
          type === "year"
            ? dayjs(date).get("year") + ""
            : dayjs(date).get("month") + 1 + "",
      });
    } else {
      setValue(undefined);
      delete allParams[filterName];
      setSearchParams({
        ...allParams,
      });
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSearchParams({
        ...allParams,
        [filterName]:
          type === "year"
            ? dayjs(defaultValue).get("year") + ""
            : dayjs(defaultValue).get("month") + 1 + "",
      });
    } else if (allParams[filterName]) {
      setValue(dayjs(allParams[filterName]));
    }
  }, []);

  return (
    <StyledDateMonthPicker>
      <DatePicker
        value={value}
        onChange={onChange}
        picker={type}
        allowClear={clearable}
        format={type === "month" ? "MM" : type === "year" ? "YYYY" : "YYYY-MM"}
        aria-readonly
      />
    </StyledDateMonthPicker>
  );
};

export default DateYearFilter;

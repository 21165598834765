import React from "react";
import { LoaderStyled } from "./Loader.style";
import { Spin } from "antd";

const Loader = () => {
  return (
    <LoaderStyled>
      <Spin  />
    </LoaderStyled>
  );
};

export default Loader;

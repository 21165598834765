import { Grid } from "@mui/material";
import { SelectForm } from "components";
import { TYPES } from "pages/monthly-assessment/common/type";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface ITypeProps {}

export const Type: FC<ITypeProps> = ({}) => {
  const { control, resetField } = useFormContext();
  const { t } = useTranslation();

  return (
    <Grid item xs={3} md={2.4}>
      <SelectForm
        control={control}
        name="type"
        onChange={() => {
          resetField("month_and_year");
          resetField("quarterId");
          resetField("classIds");
          resetField("subjectIds");
        }}
        label={t("LABELS.TYPE")!}
        options={TYPES.map((value) => ({
          ...value,
          name: t(`general.${value.name}`),
        }))}
      />
    </Grid>
  );
};

import { Box } from "@mui/material";
import styled from "styled-components";

export const DateWeekStyled = styled(Box)`
  .labelOfWeekDate {
    font-size: 14px;
    color: ${({ theme }) => theme.primary.dark};
    font-weight: 500;
    padding: 5px 0;
  }
  .week-picker-display {
    max-width: 500px;
    min-width: 250px;
    width: 100%;
    min-height: 44px;
    color: ${({ theme }) => theme.primary.dark};
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #c3c0cc;
    transition: 0.5s ease;
    padding: 5px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .week-picker-options {
    padding: 0.4rem;
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    border-radius: 5px;
    border: 1px solid #c3c0cc;
    display: flex;
    overflow: hidden;
    background: ${({ theme }) => theme.background.main};
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-week {
    padding: 0.8rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.primary.dark} !important;
  }

  .numbers-container {
    border-top: 1px solid gainsboro;
    width: 100%;
    padding: 0.3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .single-number {
    width: 100%;
    height: 40px;
    aspect-ratio: 1;
    font-size: 0.8rem;
    background: none;
    color: ${({ theme }) => theme.primary.dark};
    display: grid;
    place-items: center;
  }

  .selected-week {
    color: white;
    background-color: ${({ theme }) => theme.background.primary};
  }

  .other-month {
    background: none;
    color: ${({ theme }) => theme.primary.light};
  }

  .day {
    background: none;
    color: ${({ theme }) => theme.primary.dark};
  }

  .arrow-container {
    cursor: pointer;
    width: 25px;
    height: 25px;
    transition: 0.2s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .arrow-container svg {
    stroke: #454545;
  }

  .arrow-container:hover {
    background-color: ${({ theme }) => theme.background.primary};
    border-radius: 15%;
  }

  .arrow-container:hover svg {
    stroke: #fff;
    fill: #fff;
  }

  .other-month {
    background: none;
    color: ${({ theme }) => theme.primary.dark};
  }

  .weksOfArr {
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .oneWeek {
      width: max-content;
      height: 100%;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.background.primary};
      color: ${({ theme }) => theme.primary.dark};
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      code {
        font-size: 13px;
      }

      .oneWeekClose {
        width: 40px;
        height: 40px;
        border-radius: 10px;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
`;

import { FC } from "react";
import { Touchbar } from "./Touchbar";
import { Footer } from "./Footer";
import { useFormContext, useWatch } from "react-hook-form";

export interface IPhoneProps {}

export const Phone: FC<IPhoneProps> = () => {
  const { control } = useFormContext();
  const content = useWatch({ control: control, name: "content" });
  return (
    <div className="w-full flex justify-center bg-bgMain">
      <div className="border w-52 h-96 rounded-xl py-3 px-2">
        <div className="w-full h-full border rounded-xl">
          <Touchbar />

          <div className="bg-chat-bg bg-cover h-[270px] py-3 px-2">
            {!!content && (
              <div className="bg-bgMain rounded-lg text-xs max-h-[240px] overflow-auto scrollbar-hide p-2">
                {content}
              </div>
            )}
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

import { DefaultImage, DeleteIcon } from "assets/svgs";
import usePostImage from "hooks/usePostImage";
import { useEffect, useRef } from "react";
import {
  Control,
  Controller,
  FieldPath,
  UseFormSetValue,
} from "react-hook-form";
import { useAppSelector } from "store/storeHooks";
import { TRules } from "types/form.types";

import { IconButton } from "@mui/material";
import { Spin } from "antd";
import { ImgUploadStyled } from "styles/global.style";
interface IImageProps<FormNames extends Record<string, any>> {
  title?: string;
  // description?: string;
  rules?: TRules<FormNames>;
  control: Control<FormNames>;
  name: FieldPath<FormNames>;
  setValue: UseFormSetValue<FormNames>;
  className?: string;
  accept?: string;
  getUrl?: (url: string) => void;
  delatable?: boolean;
}
function ImageInput<FormNames extends Record<string, any>>({
  title,
  // description,
  control,
  name,
  setValue,
  rules = {
    required: {
      value: true,
      message: "Rasm yuklash majburiy",
    },
  },
  className,
  accept = "image/*",
  getUrl,
  delatable = true,
}: IImageProps<FormNames>) {
  const photoRef = useRef<any>(null);
  const isOpen = useAppSelector((store) => store.formDrawerState.isOpen);
  useEffect(() => {
    if (!isOpen) {
      // @ts-ignore
      setValue(name, undefined);
    }
  }, [isOpen]);

  const { uploadImage, imageFile, isUploading } = usePostImage((IDImage) => {
    // @ts-ignore
    setValue(name, IDImage.imageName);
    photoRef.current.value = "";
    if (getUrl) {
      getUrl(IDImage.imageName);
    }
  });

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ fieldState, field }) => {
        return (
          <ImgUploadStyled>
            {!!isUploading && (
              <div className="loading">
                <Spin />
              </div>
            )}
            {field.value ? (
              <div className="imageBox">
                <img
                  src={process.env.REACT_APP_BASE_URL + field.value}
                  alt={field.value + " image"}
                />
                {delatable && (
                  <IconButton
                    //@ts-ignore
                    onClick={() => setValue(name, null)}
                    className="deleteIcon"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ) : (
              <DefaultImage />
            )}
            <label htmlFor={name}>
              <input
                type="file"
                id={name}
                onChange={(e: any) =>
                  uploadImage({
                    imageFile: e.target.files[0],
                    id: name,
                  })
                }
                ref={photoRef}
                accept={accept}
              />
            </label>

            {fieldState.error && !imageFile && (
              <>
                <h6 className="text-error mt-1">{fieldState.error?.message}</h6>
              </>
            )}
          </ImgUploadStyled>
        );
      }}
    />
  );
}

export default ImageInput;

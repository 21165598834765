import styled from "styled-components";

export const LoaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: ${({ theme }) => theme?.background?.body};
`;

import React from "react";

const Ellips = () => {
  return (
    <>
      <svg
        width="5"
        height="5"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill="#222" />
      </svg>
    </>
  );
};

export default Ellips;

import styled from "styled-components";

export const TableContainerMain = styled.div<{
  tableHeight?: string;
  isGetAll?: boolean;
  padding?: number;
}>`
  border-radius: 0.5rem;
  width: 100%;
  position: relative;

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .no_data_content {
    padding: ${({ padding }) => padding}px;
    background-color: ${({ theme }) => theme.background.body};
    height: ${({ isGetAll, tableHeight }) =>
      tableHeight
        ? tableHeight
        : isGetAll
        ? "calc(100vh - 110px)"
        : "calc(100vh - 72px)"};
  }

  .tableHead {
    background-color: ${({ theme }) => theme.background.main};
    padding: 0px 16px 16px 16px;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  .grid-container > * {
    color: ${({ theme }) => theme.primary.dark} !important;
    font-weight: 500;
    width: 100%;
    overflow: auto;
    background-color: ${({ theme }) => theme.background.main};
  }
  .MuiDataGrid-virtualScroller {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.scrollbar.thumb};
      border-radius: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.scrollbar.track};
    }
  }
  .MuiDataGrid-footerContainer {
    display: none;
  }
  .MuiDataGrid-columnHeaderTitle {
    color: ${({ theme }) => theme.primary.dark} !important;
    font-weight: 800;
    font-size: 14px;
    text-transform: capitalize !important;
  }
  .MuiDataGrid-main > * {
    background-color: ${({ theme }) => theme.background.main};
    border-radius: 12px;
    color: ${({ theme }) => theme.primary.dark} !important;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.border.main} !important;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 18px;
  }
  .grid-container {
    width: 100%;
    height: ${({ isGetAll, tableHeight }) =>
      tableHeight
        ? tableHeight
        : isGetAll
        ? "calc(100vh - 104px)"
        : "calc(100vh - 62px - 110px)"};
    background-color: ${({ theme }) => theme.background.main};

    &.no-data {
      height: 50px;
    }
    .custom-data-grid .MuiDataGrid-overlay {
      display: none !important;
    }
  }

  .MuiDataGrid-row {
    max-height: max-content !important;
    .MuiDataGrid-cell {
      max-height: max-content !important;
      white-space: pretty !important;
    }

    &:last-child {
      .MuiDataGrid-cell {
        border-bottom: none;
      }
    }
  }

  .no-data-found {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    background-color: ${({ theme }) => theme.background.main};

    h2 {
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.primary.dark};
    }
  }

  .pagination_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 68px;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.border.main};
    background-color: ${({ theme }) => theme.background.main};
    gap: 20px;
    border-radius: 0 0 8px 8px;

    .pag_title {
      min-width: 145px;
      height: 48px;
      border: 1px solid ${({ theme }) => theme.border.main};
      background-color: ${({ theme }) => theme.background.main};
      display: flex;
      justify-content: center;
      color: ${({ theme }) => theme.primary.dark};
      font-size: 15px;
      font-weight: 700;
      align-items: center;
      gap: 5px;
      border-radius: 16px;
      cursor: pointer;

      svg {
        circle {
          fill: ${({ theme }) => theme.background.primary} !important;
        }
      }
    }
    .MuiButtonBase-root {
      width: 40px;
      height: 40px;
      border-radius: 16px;
      color: #bdbdbd;
      font-weight: 600;
      border: 1px solid ${({ theme }) => theme.border.main};

      &:hover {
        background-color: ${({ theme }) => theme.background.dark};
      }

      &.Mui-selected {
        border: 1px solid ${({ theme }) => theme.border.main};
        background-color: ${({ theme }) => theme.background.primary};
        color: #fff;
      }
    }
  }
  .table-header {
    text-transform: uppercase;
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 900 !important;
      color: ${({ theme }) => theme.primary.dark};
    }
  }
  .table_add_button {
    width: 20px !important;
  }
  .row-hover {
    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.background.info} !important;
    }
  }
  .header-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
  }
  .table_father {
    padding: ${({ padding }) => padding}px;
    background-color: ${({ theme }) => theme.background.body};
  }
  .table_header {
    min-height: 72px;
    background-color: ${({ theme }) => theme.background.main};
    padding: 0 16px;
    position: sticky;
    top: 0;
    z-index: 2;
    -webkit-box-shadow: 0px 4px 8px -6px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px -6px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px -6px rgba(34, 60, 80, 0.2);

    .title {
      color: ${({ theme }) => theme.primary.dark};
    }
  }
  .MuiCheckbox-root {
    color: ${({ theme }) => theme.primary.dark};
  }
  .MuiCheckbox-root.Mui-checked {
    color: ${({ theme }) => theme.background.primary} !important;
  }

  .MuiDataGrid-sortIcon {
    color: ${({ theme }) => theme.primary.main};
  }

  .row-back-red {
    background-color: rgba(255, 123, 123, 0.75);

    &:hover {
      background-color: rgba(243, 109, 109, 0.5) !important;
    }
  }
  .row-back-blue {
    background-color: rgba(78, 145, 253, 0.75);
    &:hover {
      background-color: rgba(78, 145, 253, 0.5) !important;
    }
  }
  .row-back-green {
    background-color: rgba(31, 198, 0, 0.75);
    &:hover {
      background-color: rgba(31, 198, 0, 0.5) !important;
    }
  }
  .row-back-white {
    background-color: rgba(255, 255, 255, 0.75);
    &:hover {
      background-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
  .row-back-yellow {
    background-color: rgba(248, 237, 0.75);
    &:hover {
      background-color: rgba(248, 237, 0.5) !important;
    }
  }
`;

export const Spinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background.loaderBack};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

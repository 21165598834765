import { Grid } from "@mui/material";
import { DateMonthPicker } from "components";
import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface IYearProps {}

export const Year: FC<IYearProps> = ({}) => {
  const { control, resetField } = useFormContext();
  const { t } = useTranslation();
  const type = useWatch({ control, name: "type" });
  const monthAndYear = useWatch({ control, name: "month_and_year" });

  useEffect(() => {
    resetField("quarterId");
    resetField("classIds");
    resetField("subjectIds");
  }, [monthAndYear]);

  if (!type) return null;

  return (
    <Grid item xs={3} md={2.4}>
      <DateMonthPicker
        control={control}
        name="month_and_year"
        label={type !== "monthly" ? t("LABELS.YEAR")! : t("LABELS.MONTH")!}
        clearable={false}
        type={type !== "monthly" ? "year" : "month"}
        // minDate={
        //   watch("quarterId")?._id ? mindate() : undefined
        // }
        //   maxDate={watch("quarterId")?._id ? maxdate() : undefined}
      />
    </Grid>
  );
};

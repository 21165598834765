import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainHeader } from "styles/global.style";
import { ClassStyle } from "./Integrations.style";
import { Navbar } from "../components/Navbar/Navbar";
import { useApi } from "hooks/useApi/useApiHooks";
import useQueryHook from "hooks/useQueryHook";
import { groupBy } from "lodash";
import { IntegrationCard } from "../components/IntegrationCard/IntegrationCard";
import { IIntegration } from "../constants/Integration.type";
import { Spin } from "antd";
import { Modal } from "../components/Modal/Modal";

export interface IIntegrationsProps {}

export const Integrations: FC<IIntegrationsProps> = () => {
  const { t } = useTranslation();
  const { QueryParams } = useQueryHook();
  const { data, isLoading, refetch } = useApi<IIntegration[]>("/integrations", {
    service: QueryParams.service === "all" ? null : QueryParams.service,
  });

  const [modal, setModal] = useState<string | boolean>(false);

  return (
    <ClassStyle>
      <MainHeader>
        <h2>{t("integrations.title")}</h2>
      </MainHeader>
      <div className="integrations_container">
        <div className="parent_box">
          <Navbar />

          {isLoading ? (
            <Spin />
          ) : (
            Object.entries(groupBy(data?.data, "service")).map((item) => (
              <>
                <p className="service-title">{item[0]}</p>

                <div className="card-wrapper">
                  {item[1].map((subitem) => (
                    <IntegrationCard {...subitem} setModal={setModal} />
                  ))}
                </div>
              </>
            ))
          )}

          {!!modal && (
            <Modal id={modal as string} setModal={setModal} refetch={refetch} />
          )}
        </div>
      </div>
    </ClassStyle>
  );
};

import { BackButton, RangeDatePicker, Table } from "components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useColumns } from "./one.columns";
import { useParams } from "react-router-dom";
import { ITeacherWorkReportInfo } from "../../types";
import { useState } from "react";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";


interface ExtendedParams {
  teacherIds: string;
  state?: string;         
  hasHomework?: boolean;  
}

export const One = () => {
  const thisMonthStart = dayjs().startOf("day").toISOString();
  const thisMonthEnd = dayjs().endOf("day").toISOString();
  const [dateRange, setDateRange] = useState({
    fromDate: thisMonthStart,
    toDate: thisMonthEnd,
  });
  const allParams = useAllQueryParams();
  const types= useParams()

  const columns = useColumns();
  const { t } = useTranslation();
  const { id } = useParams();

  const renderHeader = (
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <RangeDatePicker
        defaultValues={[dayjs(thisMonthStart).startOf("day"), dayjs(thisMonthEnd).endOf("day")]}
        onchange={(val: any) => {
          if (val) {
            setDateRange({
              fromDate: dayjs(val[0]).startOf("day").toISOString(),
              toDate: dayjs(val[1]).endOf("day").toISOString(),
            });
          }
        }}
      />
    </div>
  );

  return (
    <div>
      <Table<ITeacherWorkReportInfo>
        dataUrl={`/analitics/journal-activity/info`}
        columns={columns}
        title={t("general.teacher-work-report")}
        headerChildren={renderHeader}
        exQueryParams={{ fromDate: allParams.fromDate, toDate: allParams.toDate}}
        processingParams={(params): ExtendedParams => {
          const { type } = types; 
        
          const result: ExtendedParams = {
            ...params,
            teacherIds: JSON.stringify([id]),
          };
        
          if (type === 'checked') {
            result.state = 'checked';
          }
        
          if (type === 'hasHomework') {
            result.hasHomework = true;
          }
        
          return result; 
        }}
        
        
        back={false}
        manualBackButton={<BackButton to="/analitics/teacher-work-reports" />}
      />
    </div>
  );
};

import styled from "styled-components";

export const SearchInputStyled = styled.div`
  .search-box {
    width: fit-content;
    height: fit-content;
    position: relative;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.background.info};
    display: flex;
  }
  .input-search {
    height: 42px;
    width: 300px;
    border-style: none;
    padding: 10px 15px;
    font-size: 14px;
    outline: none;
    padding-left: 45px;
    background-color: ${({ theme }) => theme.background.info};
    color: ${({ theme }) => theme.primary.dark};
    border: none !important;
    border-radius: 12px;
  }
  .input-search::placeholder {
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 100;
    color: #999999;
  }
  .btn-search {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    left: 0px;
    color: #ffffff;
    background-color: transparent;
    pointer-events: painted;
    svg {
      font-size: 20px;
      path {
        stroke: #999;
      }
    }
  }
`;

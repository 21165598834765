import {
  DataGrid,
  GridColumns,
  GridRowParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useApi, useApiMutation } from "hooks/useApi/useApiHooks";
import useDebounce from "hooks/useDebounce";
import useAllQueryParams from "hooks/useGetAllQueryParams/useAllQueryParams";
import { get } from "lodash";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "store/storeHooks";
import { ITable, ITableData } from "./Table.constants";
import { Spinner, TableContainerMain } from "./Table.style";
import NoDataFound from "./components/noDataFound";
import TableHeader from "./components/tableHeader";
import TablePagination from "./components/tablePagination";
import { reRenderTable } from "./reducer/table.slice";
import { getTableColumns, localization } from "./utils";
import { Spin } from "antd";

const Table = <TData extends { _id: string }>({
  onEditColumn,
  onDeleteColumn,
  onRowClick = undefined,
  onAddButton,
  onDeleteSuccess,
  onDataChange,
  getAllData,
  onSeenClick,
  isRowSelectable = () => true,
  archiveIcon,
  unArchiveIcon,
  dataUrl,
  deleteUrl,
  columns,
  title,
  exQueryParams = {},
  tableHeight,
  searchable = false,
  exportBtn = false,
  deletable = false,
  selection = deletable ? true : false,
  hasPagination = true,
  numerate = true,
  isGetAll = false,
  addButtonTitle,
  setRowSelection,
  noRerender,
  back,
  manualBackButton,
  tableHead,
  processingParams,
  dataChart,
  headerChildren,
  headerChildrenSecondRow,
  showHeader = true,
  padding = 16,
  rowBackgroundColorKey,
  processRowUpdate,
  onProcessRowUpdateError,
  onEditRow,
  footerComponent,
  getUniqueId,
  deleteTitle,
  footer,
  additionalActions,
  rowClassName,
}: ITable<TData>) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [__, setSearchParams] = useSearchParams();
  const allParams = useAllQueryParams();
  const [search, setSearch] = useState<string>(allParams.search || "");
  const { debouncedValue: debValue } = useDebounce(search, 500);
  const isOpen = useAppSelector((store) => store.formDrawerState.isOpen);
  const reRender = useAppSelector((store) => store.tableState.render);
  const { search: locationSearch } = useLocation();
  const defaultLimit = 20;

  const filterParams = useMemo(() => {
    if (processingParams) {
      const params: Record<string, any> = {};
      const queryData = processingParams(allParams);
      Object.entries(queryData).forEach(([key, value]) => {
        if (value) {
          params[key] = value;
        }
      });

      return params;
    }

    return undefined;
  }, [locationSearch]);

  const dis = useDispatch();
  const { t } = useTranslation();

  /** @todo work with query params */
  useEffect(() => {
    if (!isGetAll) {
      setSearchParams({
        ...allParams,
        search: search || "",
        page: search ? "1" : allParams.page || "1",
        limit: allParams.limit || "20",
      });
    }
  }, [debValue]);

  /** @todo to get data */
  const { data, refetch, isFetching } = useApi<ITableData>(
    dataUrl,
    {
      ...(filterParams ? { ...filterParams } : { ...allParams }),
      ...exQueryParams,
      search: debValue || "",
      page: search ? "1" : allParams.page || "1",
      limit: allParams.limit || defaultLimit + "",
    },
    {
      onSuccess(data) {
        const tableData = isGetAll ? get(data, "data", []) : data?.data?.data;
        onDataChange?.(tableData);
        getAllData?.(data?.data);
      },
      suspense: false,
    }
  );

  /** @todo to delete */
  const { mutate: deleteMutate, isSuccess: isDeleteSuccess } = useApiMutation(
    deleteUrl || dataUrl,
    "delete"
  );
  const onDelete = () => {
    deleteMutate({
      ids: selectedRows,
    });
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      refetch();
      onDeleteSuccess?.();
    }
    if (reRender && !noRerender) {
      refetch();
      dis(reRenderTable(false));
      setSelectedRows([]);
    }
  }, [isDeleteSuccess, isOpen, reRender]);

  /** @constant memorize fetched data */
  const tableData: TData[] = React.useMemo(() => {
    const dataKey: any[] = isGetAll
      ? get(data, "data", [])
      : get(data, "data.data", []);

    if (isGetAll && searchable && search) {
      return dataKey?.filter(
        (item) =>
          item?.firstName?.toLowerCase()?.includes(search) ||
          item?.lastName?.toLowerCase()?.includes(search) ||
          item?.name?.toLowerCase()?.includes(search) ||
          item?.car?.name?.toLowerCase()?.includes(search)
      );
    }
    return dataKey?.map((item: Record<string, any>, i: number) => {
      const index = isGetAll
        ? 0
        : ((+allParams?.page || 1) - 1) * (+allParams?.limit || defaultLimit);
      return {
        ...item,
        _id: getUniqueId
          ? getUniqueId(item)
          : item?._id || `${i}${i + 1 + index}`,
        _number: i + 1 + index,
      };
    });
  }, [data, search]);

  /** @constant memorize columns */
  const tableColumns: GridColumns<TData> = React.useMemo(
    () =>
      getTableColumns<TData>({
        columns,
        numerate,
        onDeleteColumn,
        archiveIcon,
        unArchiveIcon,
        onEditColumn,
        onSeenClick,
        deleteTitle: deleteTitle || t("general.delete"),
        updateTitle: t("general.update"),
        additionalActions,
      }),
    [columns]
  );
  const totalData = data?.data?.total || tableData?.length || 0;
  const footerData = data?.data?.footerData || 0;

  const getSelectedRows = (rowIds: GridSelectionModel) => {
    const selectedRowsData = rowIds.map((id) =>
      tableData.find((row) => row._id === id)
    );
    setRowSelection?.(selectedRowsData);
  };

  const getRowClassName = (params: any) => {
    switch (get(params, `row.${rowBackgroundColorKey}`)) {
      case "red":
        return "row-back-red";
      case "yellow":
        return "row-back-yellow";
      case "green":
        return "row-back-green";
      case "white":
        return "row-back-white";
      case "blue":
        return "row-back-blue";
      default:
        return "";
    }
  };

  return (
    <>
      <TableContainerMain
        tableHeight={tableHeight}
        isGetAll={isGetAll}
        padding={padding}
      >
        {showHeader && (
          <TableHeader
            title={title}
            addButtonTitle={addButtonTitle}
            searchable={searchable}
            exportBtn={exportBtn}
            setSearch={setSearch}
            search={search}
            headerChildren={headerChildren}
            headerChildrenSecondRow={headerChildrenSecondRow}
            deletable={deletable}
            selectedRows={selectedRows}
            onAddButton={onAddButton}
            onDelete={onDelete}
            dataUrl={dataUrl}
            back={back}
            manualBackButton={manualBackButton}
          />
        )}
        {tableHead && <div className="tableHead">{tableHead}</div>}
        {tableData?.length === 0 && !isFetching ? (
          <div className="no_data_content">
            <div className="grid-container no-data ">
              <DataGrid rows={[]} columns={tableColumns} />
            </div>
            <NoDataFound />
          </div>
        ) : (
          <div className="table_father">
            <div
              className="grid-container"
              style={
                dataChart
                  ? {
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      justifyContent: "space-between",
                    }
                  : {}
              }
            >
              <DataGrid
                getRowId={(row: any) => row?._id}
                rows={tableData}
                columns={tableColumns}
                localeText={localization}
                pageSize={+allParams?.limit || defaultLimit}
                rowsPerPageOptions={[5, 10, 20]}
                loading={isFetching}
                hideFooterPagination
                sortingMode="server"
                // onProcessRowUpdateError={onProcessRowUpdateError}
                editMode={"row"}
                // processRowUpdate={(updatedRow, originalRow) =>
                //   processRowUpdate?.(updatedRow, originalRow)
                // }
                onRowEditStop={(data) => onEditRow(data)}
                sortModel={
                  allParams?.sortBy && !isFetching
                    ? [
                        {
                          sort: allParams?.sortOrder === "1" ? "asc" : "desc",
                          field: allParams?.sortBy,
                        },
                      ]
                    : []
                }
                onSortModelChange={(model) => {
                  if (model.length) {
                    setSearchParams({
                      ...allParams,
                      sortBy: `${model[0].field}`,
                      sortOrder: `${model[0].sort === "asc" ? 1 : -1}`,
                    });
                  } else {
                    delete allParams.sortBy;
                    delete allParams.sortOrder;
                    setSearchParams({
                      ...allParams,
                    });
                  }
                }}
                disableSelectionOnClick
                isRowSelectable={(params: GridRowParams<TData>) =>
                  isRowSelectable?.(params.row)
                }
                onPageSizeChange={(newPageSize) =>
                  setSearchParams({
                    ...allParams,
                    limit: String(newPageSize),
                  })
                }
                onRowClick={(props) => {
                  onRowClick?.(props.row);
                }}
                rowCount={totalData}
                getRowClassName={(params) => {
                  if (rowClassName) {
                    return rowClassName(params);
                  }
                  return rowBackgroundColorKey
                    ? getRowClassName(params)
                    : onRowClick
                    ? "row-hover"
                    : "";
                }}
                checkboxSelection={selection}
                onSelectionModelChange={(rows, data) => {
                  setSelectedRows(rows);
                  getSelectedRows(rows);
                }}
                selectionModel={selectedRows}
                paginationMode="server"
                sx={{ height: "100%" }}
                headerHeight={48}
                className="custom-data-grid"
                showColumnRightBorder
                showCellRightBorder
                components={{
                  Footer: () =>
                    footerComponent
                      ? footerComponent(tableColumns, footerData)
                      : undefined,
                }}
              />
              {dataChart}
            </div>
            <div className="flex justify-between bg-bgMain items-center">
              {footer && typeof footer === "function"
                ? footer(data?.data)
                : footer}
              {!!allParams && hasPagination && !isGetAll && (
                <TablePagination
                  totalData={totalData}
                  tableData={tableData}
                  defaultLimit={defaultLimit}
                />
              )}
            </div>
          </div>
        )}

        {isFetching && (
          <Spinner>
            <Spin />
          </Spinner>
        )}
      </TableContainerMain>
    </>
  );
};

export default Table;

import { FC } from "react";
import { Phone } from "../Phone";
import { useGetSmsVariables } from "../../hooks/useGetSmsVariables";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

export interface IRightSideProps {}

export const RightSide: FC<IRightSideProps> = ({}) => {
  const smsTemplateVariables = useGetSmsVariables();
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  return (
    <div>
      <Phone />
      <h1 className="text-lg font-bold my-2">{t("general.variables")}</h1>
      <div className="h-48 overflow-auto">
        {smsTemplateVariables.map((item) => (
          <div
            className="border my-1 px-1 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setValue(
                "content",
                (getValues("content") || "") + `{${item.value}}`
              );
            }}
          >
            {item.keyword}
          </div>
        ))}
      </div>
    </div>
  );
};

import { Grid } from "@mui/material";
import { AutoCompleteForm } from "components";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface IQuarterProps {}

export const Quarter: FC<IQuarterProps> = ({}) => {
  const { control, resetField } = useFormContext();
  const type = useWatch({ name: "type", control });
  const year = useWatch({ name: "month_and_year", control });
  const { t } = useTranslation();

  if (type !== "quarterly" || !year) return null;

  return (
    <Grid item xs={3} md={2.4}>
      <AutoCompleteForm
        onChange={() => {
          resetField("classIds");
          resetField("subjectIds");
        }}
        control={control}
        name="quarterId"
        clearable={false}
        returnOnlyId={true}
        getLabelOption={(option) =>
          `${option?.quarter} ${t("general.quarter")}`
        }
        label={t("LABELS.QUARTER")!}
        optionsUrl="quarter"
        exQueryParams={{
          limit: 200,
          page: 1,
        }}
      />
    </Grid>
  );
};

import React, { useState, useEffect, memo } from "react";
import dayjs from "dayjs";
import { CurrentTimeStyle } from "./CurrentDate.style";

interface CurrentTimeProps {
  format?: string;
}

const CurrentTime: React.FC<CurrentTimeProps> = ({ format = "HH:mm:ss" }) => {
  const [currentTime, setCurrentTime] = useState<string>(
    dayjs().format(format)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs().format(format));
    }, 1000);

    return () => clearInterval(interval);
  }, [format]);

  return (
    <CurrentTimeStyle>
      <p>
        {dayjs().format("DD.MM.YYYY")} {currentTime}
      </p>
    </CurrentTimeStyle>
  );
};

export default memo(CurrentTime);

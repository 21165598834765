import { FC } from "react";
import styled from "styled-components";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";

export interface ICheckCircleProps {
  color?: string;
}

const StyledCheckCircleIcon = styled(CheckCircleIcon)<ICheckCircleProps>`
  color: ${(props) => props.color || "green"};
`;

export const CheckCircle: FC<ICheckCircleProps> = ({ color, ...props }) => {
  return <StyledCheckCircleIcon color={color as any} {...props} />;
};

import { MenuItem, Select } from "@mui/material";
import styled from "styled-components";

export const SelectStyle = styled.div`
  label {
    color: ${({ theme }) => theme.primary.dark};
    padding: 4px 0;
    font-weight: 500;
  }
`;
export const SelectStyled = styled(Select)`
  height: 48px;
  font-size: 17px !important;
  background-color: ${({ theme }) => theme.background.info};
  border-radius: 12px !important;
  /* padding: 12px !important; */
  border: 2px solid ${({ theme }) => theme.background.info};
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.primary.dark} !important;

  &:hover {
    border: 2px solid ${({ theme }) => theme.background.primary} !important;
  }
  &:focus {
    border: 2px solid ${({ theme }) => theme.background.primary} !important;
  }

  gap: 10px;

  .MuiSvgIcon-root {
    /* display: none; */
    color: ${({ theme }) => theme.primary.dark};
  }
  .clear-btn {
    margin-right: 10px;
    /* display: none; */
    /* opacity: 0.01; */
    /* opacity: 0.01; */
    opacity: 0.5;
    /* display: none; */
  }
  .clear-btn:hover {
    /* opacity: 1; */
  }

  & .MuiPaper-root {
    width: 200px !important;
  }
`;

export const SelectMenuStyled = styled(MenuItem)`
  width: 100% !important;
  overflow: hidden !important;

  &:has() {
    /* background-color: aqua; */
  }
`;

export const OnAddButton = styled(MenuItem)`
  width: 100%;
  color: darkblue !important;
  background-color: ${({ theme }) => theme.background.info} !important;
  font-weight: 600 !important;
  padding-right: 20px !important;
`;

import styled, { keyframes } from "styled-components";
type IStyled = { fullWidth: boolean };

const InputAnimate = keyframes`
 0% {
        transform: scale(1);
    }
    40%{
        transform: scale(1.3,0.7);
    }
    55% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.2,0.8);
    }
    80% {
        transform: scale(1);
    }
    90% {
        transform: scale(1.1,0.9);
    }
    100% {
        transform: scale(1);
    }
`;
const InputCheck = keyframes`
   0% {
        transform: scale(0) rotate(-45deg);
    }
    100% {
        transform: scale(1) rotate(-45deg);
    }
`;

export const CheckboxStyled = styled.div<IStyled>`
  width: max-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary.dark};
  }

  label input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  label input:checked {
    & ~ .input-check {
      background-color: ${({ theme }) => theme.background.primary};
      border-color: ${({ theme }) => theme.background.primary};
      animation-name: ${InputAnimate};
      animation-duration: 0.7s;
    }
  }
  label input:checked {
    & ~ .input-check:before {
      transform: scale(1) rotate(-45deg);
      animation-name: ${InputCheck};
      animation-duration: 0.2s;
      animation-delay: 0.3s;
    }
  }

  label .input-check {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: 2px solid #ccc;
    position: relative;
    margin-right: 7px;
    transition: 0.4s;
    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 4px;
      border-bottom: 3px solid #fff;
      border-left: 3px solid #fff;
      transform: scale(0) rotate(-45deg);
      position: absolute;
      top: 6px;
      left: 4px;
      transition: 0.4s;
    }
  }
`;

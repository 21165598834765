import { FC } from "react";
import StyledModal from "./Modal.style";
import { Button, DialogProps } from "@mui/material";
import { useApi, useApiMutation } from "hooks/useApi/useApiHooks";
import { IIntegration } from "../../constants/Integration.type";
import { generateUploadUrl } from "utils/generateUploadUrl";
import { useTranslation } from "react-i18next";
import { ModalContent } from "./ModalContent.tsx";
import { InstallButton } from "../ActionButtons/Installment";

export interface IModalProps extends Partial<DialogProps> {
  setModal: (state: string | boolean) => void;
  id: string;
  refetch: any;
}

export const Modal: FC<IModalProps> = ({
  setModal,
  id,
  refetch: refetchGeneralData,
}) => {
  const { data, refetch } = useApi<IIntegration>(
    `/integrations/${id}`,
    undefined,
    {
      queryKey: "integration-by-id",
    }
  );

  const { mutateAsync } = useApiMutation(`integration/uninstall/${id}`, "post");

  const { t } = useTranslation();
  function handleUninstall() {
    mutateAsync({}).then(() => refetch());
  }

  return (
    <StyledModal
      onClose={() => {
        setModal(false);
        refetchGeneralData();
      }}
      open
    >
      <div className="modal-sidebar">
        <div className="image-wrapper">
          <img src={generateUploadUrl(data?.data?.images[0]!)} alt={id} />
        </div>

        <p className="service-name">{data?.data?.name}</p>
        {data?.data?.isInstalled ? (
          <div className="button-wrapper">
            <Button className="card-button__installed">
              {t("integrations.installed")}
            </Button>
            <Button
              onClick={() => handleUninstall()}
              className="card-button__install"
            >
              {t("integrations.uninstall")}
            </Button>
          </div>
        ) : (
          <InstallButton refetch={refetch} id={id} />
        )}
      </div>
      <ModalContent refetch={refetch} data={data?.data!} />
    </StyledModal>
  );
};

import styled from "styled-components";

export const IntegrationCardWrapper = styled.div`
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 166px;
  display: inline-block;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 10px;
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.background.dark};
    background-color: ${({ theme }) => theme.background.main};

    img {
      height: 100px;
      width: 135px;
      object-fit: contain;
    }
  }
  .card-name {
    margin: 4px 0 8px 0;
  }

  .card-button {
    background-color: ${({ theme }) => theme.background.info};
    border: 1px solid ${({ theme }) => theme.background.info};
  }
`;

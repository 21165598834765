import { CommonModal } from "components";
import { ICommonModalProps } from "components/common/commonModal";
import { FC } from "react";
import { ICRUDManagerProps } from ".";
import { FormProvider, useForm } from "react-hook-form";
import { useSmsTemplateSchema } from "../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { InferType } from "yup";
import { RightSide } from "../RightSide";
import { LeftSide } from "../LeftSide";
import { Header } from "../Header";
import { useApiMutation } from "hooks/useApi/useApiHooks";

export interface ICreateModalProps
  extends Partial<ICommonModalProps>,
    ICRUDManagerProps {}

export const CreateModal: FC<ICreateModalProps> = ({ action, setAction }) => {
  const smsTemplateSchema = useSmsTemplateSchema();
  const { mutateAsync } = useApiMutation("sms-templates", "post");

  const methods = useForm({
    resolver: yupResolver(smsTemplateSchema),
  });

  function onSubmit(data: InferType<typeof smsTemplateSchema>) {
    mutateAsync(data).then((data) => {
      setAction(null);
    });
  }

  return (
    <CommonModal open={!!action?.name} setOpen={setAction} width={1000}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Header setAction={setAction} />
        <FormProvider {...methods}>
          <div className="flex w-full gap-2">
            <LeftSide />
            <RightSide />
          </div>
        </FormProvider>
      </form>
    </CommonModal>
  );
};

import React, { FC, memo } from "react";
import { Controller } from "react-hook-form";
import { ITimePicker } from "./TimePicker.types";
//@ts-ignore
import TimePickerCom from "react-time-picker";
import { useTranslation } from "react-i18next";
import { ErrorLabelStyle } from "styles/global.style";
import { TimePickerContainer } from "./TimePicker.style";
import { SelectLableStyled } from "../select/SelectForm.style";

const TimePicker: FC<ITimePicker> = ({
  control,
  name,
  errors,
  rules,
  label,
  icon,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SelectLableStyled>
        {label && (
          <label>
            {label} {icon && icon}{" "}
            {rules?.required && <span className="error">*</span>}
          </label>
        )}
      </SelectLableStyled>
      <TimePickerContainer isError={!!errors[name]}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { onChange, value } }) => (
            <TimePickerCom
              clockIcon={null}
              className="time-picker"
              onChange={onChange}
              value={value}
              format="HH:mm"
              hourPlaceholder="HH"
              minutePlaceholder="mm"
              locale="uz-UZ"
              disabled={disabled}
            />
          )}
        />
        {!!errors[name] && (
          <ErrorLabelStyle>&nbsp;{t("general.wrong_text")}</ErrorLabelStyle>
        )}
        {typeof errors === "boolean" && errors && (
          <ErrorLabelStyle>&nbsp;{t("general.wrong_text")}</ErrorLabelStyle>
        )}
      </TimePickerContainer>
    </>
  );
};

export default memo(TimePicker);

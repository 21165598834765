import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import { ArrowLeft } from "./assets/ArrowLeft";
import { ArrowRight } from "./assets/ArrowRight";
import dayjs from "dayjs";
import { DateWeekStyled } from "./DateWeekStyle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

dayjs.locale("en");

interface IHonestWeekPicker {
  onChange: any;
  label?: string;
  isMulti?: boolean;
  status?: string;
  defaultWeek?: Record<string, any>;
  weeks?: Record<string, any>[];
  setWeeks?: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
}
const HonestWeekPicker = ({
  onChange,
  label = "general.week",
  isMulti = false,
  status,
  defaultWeek,
  setWeeks,
  weeks,
}: IHonestWeekPicker) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [week, setWeek] = useState<any>({
    fromDate: dayjs().startOf("week"),
    toDate: dayjs().endOf("week"),
  });

  useEffect(() => {
    onChange && onChange(week);
  }, [week]);

  useEffect(() => {
    if (status === "success") {
      setWeeks?.([]);
    }
  }, [status]);

  const isLeapYear = () => {
    const leapYear = new Date(new Date().getFullYear(), 1, 29);
    return leapYear.getDate() == 29;
  };

  const handleClick = (e: any) => {
    let localDate;
    if (e.target.id.includes("prev")) {
      localDate = new Date(date.setDate(1));
      setDate(new Date(date.setDate(1)));
    } else if (e.target.id.includes("next")) {
      localDate = new Date(date.setDate(dayjs(date).daysInMonth()));
      setDate(new Date(date.setDate(dayjs(date).daysInMonth())));
    } else {
      localDate = new Date(date.setDate(e.target.id));
      setDate(new Date(date.setDate(e.target.id)));
    }
    const fromDate = dayjs(localDate).startOf("week");
    const toDate = dayjs(localDate).endOf("week");
    setWeek({ fromDate, toDate });
  };

  const months: string[] = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun",
    "July",
    "Aug.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];

  const days: Record<string, any> = {
    "1": 31,
    "2": isLeapYear() ? 29 : 28,
    "3": 31,
    "4": 30,
    "5": 31,
    "6": 30,
    "7": 31,
    "8": 31,
    "9": 30,
    "10": 31,
    "11": 30,
    "12": 31,
  };

  const renderDays = () => {
    const month: any = date.getMonth() + 1;
    const ar: any = [];
    for (let i = 1; i <= days[month]; i++) {
      const currentDate = new Date(date).setDate(i - 1);

      let cName = "single-number ";
      if (
        new Date(week.fromDate).getTime() <= new Date(currentDate).getTime() &&
        new Date(currentDate).getTime() <= new Date(week.toDate).getTime()
      ) {
        cName = cName + " selected-week";
      }

      ar.push(
        <div key={v4()} id={i + ""} className={cName} onClick={handleClick}>
          {i}
        </div>
      );
    }

    const displayDate = new Date(date).setDate(1);
    let dayInTheWeek = new Date(displayDate).getDay();
    if (dayInTheWeek < 1) {
      dayInTheWeek = 7;
    }
    const prevMonth = [];
    let prevMonthDays = new Date(date).getMonth();
    if (prevMonthDays === 0) {
      prevMonthDays = 12;
    }
    for (let i = dayInTheWeek; i > 1; i--) {
      const previousMonth = new Date(date).setMonth(
        new Date(date).getMonth() - 1
      );
      const currentDate = new Date(previousMonth).setDate(
        days[prevMonthDays] - i + 2
      );
      let cName = "single-number other-month";
      const currentTime = new Date(currentDate).getTime();
      const firstTime = new Date(week.fromDate).getTime();
      const endTime = new Date(week.toDate).getTime();
      if (currentTime >= firstTime && currentTime <= endTime) {
        cName = "single-number selected-week";
      }

      prevMonth.push(
        <div
          onClick={handleClick}
          key={v4()}
          id={"prev-" + i}
          className={cName}
        >
          {days[prevMonthDays] - i + 2}
        </div>
      );
    }

    const nextMonth = [];
    let fullDays = 35;
    if ([...prevMonth, ...ar].length > 35) {
      fullDays = 42;
    }

    for (let i = 1; i <= fullDays - [...prevMonth, ...ar].length; i++) {
      let cName = "single-number other-month";
      const toDate = new Date(week.toDate).getTime();
      const fromDate = new Date(week.fromDate).getTime();
      const lastDayOfMonth = new Date(
        new Date(date).setDate(dayjs(date).daysInMonth())
      );

      if (
        lastDayOfMonth.getTime() <= toDate &&
        fromDate <= lastDayOfMonth.getTime()
      ) {
        cName = "single-number selected-week";
      }

      nextMonth.push(
        <div
          onClick={handleClick}
          key={v4()}
          id={"next-" + i}
          className={cName}
        >
          {i}
        </div>
      );
    }
    return [...prevMonth, ...ar, ...nextMonth];
  };

  const handleDate = (next?: boolean) => {
    let localDate: any = new Date(date);
    if (next) {
      localDate = dayjs(localDate).add(1, "month");
    } else {
      localDate = dayjs(localDate).add(-1, "month");
    }
    setDate(new Date(localDate));
  };

  const handleDeleteWeek = (index: number) => {
    const filtered = weeks?.filter((week, ind: number) => ind !== index);
    setWeeks?.(filtered || []);
  };
  const { t } = useTranslation();

  return (
    <DateWeekStyled>
      <label className="labelOfWeekDate">{t(label)}</label>
      <div
        className="week-picker-display"
        onBlur={() => setOpen(false)}
        onClick={() => setOpen(true)}
        tabIndex={0}
      >
        {isMulti ? (
          <div className="weksOfArr">
            {weeks?.map((week, index) => (
              <p className="oneWeek">
                <code>
                  {dayjs(week.fromDate).add(1, "day").format("DD.MM.YYYY")} -{" "}
                  {dayjs(week.toDate).add(1, "day").format("DD.MM.YYYY")}
                </code>
                <IconButton
                  onClick={() => handleDeleteWeek(index)}
                  className="oneWeekClose"
                >
                  <CloseIcon />
                </IconButton>
              </p>
            ))}
          </div>
        ) : (
          <p>
            {defaultWeek ? (
              <>
                {dayjs(defaultWeek.fromDate).format("DD.MM.YYYY") +
                  "-" +
                  dayjs(defaultWeek.toDate).format("DD.MM.YYYY")}
              </>
            ) : (
              <>
                {dayjs(week.fromDate).add(1, "day").format("DD.MM.YYYY") +
                  "-" +
                  dayjs(week.toDate).add(1, "day").format("DD.MM.YYYY")}
              </>
            )}
          </p>
        )}
        {open && (
          <div className="week-picker-options">
            <div className="title-week">
              <div onClick={() => handleDate()} className="arrow-container">
                {ArrowLeft}
              </div>
              {`${months[date.getMonth()]} ${date.getFullYear()}.`}
              <div onClick={() => handleDate(true)} className="arrow-container">
                {ArrowRight}
              </div>
            </div>
            <div className="numbers-container">
              <div className="single-number day">Mon</div>
              <div className="single-number day">Tue</div>
              <div className="single-number day">Wed</div>
              <div className="single-number day">Thu</div>
              <div className="single-number day">Fri</div>
              <div className="single-number day">Sat</div>
              <div className="single-number day">Sun</div>
            </div>
            <div className="numbers-container">{renderDays()}</div>
          </div>
        )}
      </div>
    </DateWeekStyled>
  );
};

export default HonestWeekPicker;

import styled from "styled-components";

export const CurrentTimeStyle = styled("div")`
  p {
    font-size: 28px;
    font-weight: 700;
    color: ${({ theme }) => theme.background.secondary};
    letter-spacing: 2px;
  }
`;

import { GridColumns } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

interface IUseList {
  _id: string;
  title: string;
  content: string;
}

export const useListColumns = (): GridColumns<IUseList> => {
  const { t } = useTranslation();

  return [
    {
      field: t("TABLE.TITLE"),
      renderCell(params) {
        return params.row.title;
      },
    },
    {
      field: t("TABLE.CONTENT"),
      renderCell(params) {
        return params.row.content;
      },
    },
  ];
};

import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  min-width: 80px !important;
  height: 42px;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  border-radius: 8px !important;
  line-height: 48px;
  background-color: ${({ theme }: any) => theme.background.primary} !important;
  color: #fff !important;
  border: none;
  outline: none;
`;

import { TextInput } from "components";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Suggessions } from "../Suggessions";
import { useTranslation } from "react-i18next";

export interface ILeftSideProps {}

export const LeftSide: FC<ILeftSideProps> = ({}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <div className="w-[70%] shrink-0 flex">
      <div className="w-full">
        <TextInput
          control={control}
          name="title"
          label={t("general.title")!}
          placeholder={t("general.title")!}
          className="w-full"
        />
        <br />
        <TextInput
          className="h-full"
          control={control}
          name="content"
          label={t("general.content")!}
          placeholder={t("general.content")!}
          multiline
          rows={19}
        />
        <Suggessions />
      </div>
    </div>
  );
};

import { FC } from "react";
import { IStudent } from "../../types";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface IStudentNamesProps {
  students: IStudent[];
}

export const StudentNames: FC<IStudentNamesProps> = ({ students }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (students.length < 4) {
    return (
      <div>
        {students.map((item, index, array) => (
          <>
            <span
              onClick={() => navigate(`/students/info/${item._id}`)}
              className="mr-1 cursor-pointer"
            >{`${item.firstName} ${item.lastName}${
              index < array.length - 1 ? "," : ""
            }`}</span>
          </>
        ))}
      </div>
    );
  }

  return (
    <Collapse
      accordion
      items={[
        {
          key: "see all",
          label: t("general.see_all_students"),
          children: (
            <div>
              {students.map((item) => (
                <div
                  onClick={() => navigate(`/students/info/${item._id}`)}
                  className="cursor-pointer"
                >{`${item.firstName} ${item.lastName}`}</div>
              ))}
            </div>
          ),
        },
      ]}
    />
  );
};

import styled from "styled-components";

export const RangeDatePickerStyled = styled.div`
  .ant-picker {
    width: 220px;
    height: 42px !important;
    border-radius: 12px;
    border: 2px solid ${({ theme }) => theme.background.info};
    box-shadow: none;
    background-color: ${({ theme }) => theme.background.info} !important;
    margin: 0 !important;

    .ant-picker-active-bar {
      background-color: ${({ theme }) => theme.background.primary};
    }

    &:hover {
      border: 2px solid ${({ theme }) => theme.background.primary};
    }
    input {
      font-size: 12px;
      color: ${({ theme }) => theme.primary.dark} !important;
    }
  }

  .ant-picker-input {
    input {
      &::placeholder {
        color: ${({ theme }) => theme.secondary.main};
      }
    }
  }
`;

import { Dispatch, FC, SetStateAction } from "react";
import { DeleteModal } from "./Delete.modal";
import { CreateModal } from "./Create.modal";
import { EditModal } from "./Edit.modal";

export type ActionNameTypes = "create" | "edit" | "delete" | null;

export type IAction = {
  name: ActionNameTypes;
  data?: ISmsTemplate;
} | null;

export interface ISmsTemplate {
  _id?: string;
  content: string;
  title: string;
}

export interface ICRUDManagerProps {
  action: IAction;

  setAction: Dispatch<SetStateAction<IAction>>;
}

export const CRUDManager: FC<ICRUDManagerProps> = ({ ...props }) => {
  switch (props.action?.name) {
    case "create":
      return <CreateModal {...props} />;
    case "edit":
      return <EditModal {...props} />;
    case "delete":
      return <DeleteModal {...props} />;
    default:
      return null;
  }
};

export const lightMode: any = {
  background: {
    main: "#ffffff",
    primary: "#249EB1",
    secondary: "#249EB1",
    info: "#f5f5f5",
    error: "rgba(185, 55, 146,0.15)",
    dark: "#e9e9e9",
    body: "#f5f5f5",
    disabled: "#E9ECEF",
    loaderBack: "rgba(255,255,255,0.4)",
    selected: "rgba(36, 158, 177,0.4)",
  },
  primary: {
    main: "#249EB1",
    light: "#fff",
    dark: "#000",
    disabled: "#737887",
    secondary: "#a9a9a9",
  },
  secondary: {
    main: "#949494",
    light: "#e9e9e9",
    dark: "#999",
  },
  form: {
    main: "#eef3f7",
  },
  success: {
    main: "#00c537",
  },
  black: {
    main: "rgb(255, 255, 255) ",
  },
  white: {
    main: "rgb(24, 26, 27)",
  },
  error: {
    main: "#e34a29",
    light: "#FFF5F8",
    primary: "#FF4848",
  },
  info: {
    main: "#095379",
  },
  warning: {
    main: "#FBB500",
  },
  hover: {
    main: "rgba(35, 120, 144,0.1)",
    secondary: "rgba(21, 48, 67,0.1)",
  },
  scrollbar: {
    thumb: "#d9d9d9",
    track: "#E9ECEF",
  },
  border: {
    main: "#e9e9e9",
  },
  boxShadow: {
    main: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
  },
  sidebar: {
    main: "#fff",
  },
  chat: {
    sender_message_back: "#3D68FF",
    receive_message_back: "#ffffff",
  },
  transparent: "transparent",
};

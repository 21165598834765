import { GridColumns } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { ITeacherWorkReportInfo } from "../../types";
import { get } from "lodash";
import dayjs from "dayjs";

export const useColumns = (): GridColumns<ITeacherWorkReportInfo> => {
  const { t } = useTranslation();

  return [
    {
      field: t("TABLE.CLASS"),
      renderCell({ row }) {
        return get(row, "class.grade", "") + " " + get(row, "class.letter", "");
      },
    },
    {
      field: t("TABLE.SUBJECT"),
      renderCell({ row }) {
        return get(row, "subject.name", "");
      },
    },
    {
      field: t("TABLE.DAY"),
      renderCell({ row }) {
        const date = get(row, "date");
        if (!date) {
          return "";
        }
        return new Date(get(row, "date")).toLocaleDateString("ru");
      },
    },
    {
      field: t("TABLE.TIME"),
      renderCell({ row }) {
        return dayjs(row.date).format("h:mm");
      },
    },
    {
      field: t("TABLE.HOMEWORK"),
      renderCell({ row }) {
        if (!row.homework.length) return null;

        return row.homework.map((item) => item.comment).join("\n");
      },
    },
  ];
};

import { FC, useState } from "react";
import { IStudent } from "../../types";
import { CommonButton, CommonModal } from "components";
import { useTranslation } from "react-i18next";
import { StudentNames } from "./StudentNames";
import { FormProvider, useForm } from "react-hook-form";
import { SmsTemplates } from "./SmsTemplates";
import { SelectTemplates } from "./SelectTemplates";
import { SelectIntegration } from "./SelectIntegration";
import { useSmsSchema } from "../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { InferType } from "yup";
import { useApiMutation } from "hooks/useApi/useApiHooks";

export interface ISendSmsProps {
  selectedRows: IStudent[];
}

export const SendSms: FC<ISendSmsProps> = ({ selectedRows }) => {
  const smsSchema = useSmsSchema();
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const { mutateAsync } = useApiMutation("/sms", "post");

  const methods = useForm({
    resolver: yupResolver(smsSchema),
  });

  function onSubmit(data: InferType<typeof smsSchema>) {
    const payload = {
      content: data.content,
      integrationId: data.integrationId,
      studentIds: selectedRows.map((item) => item._id),
      toStudent: true,
      type: "student",
    };
    mutateAsync(payload).then(() => setModal(false));
  }

  if (!selectedRows.length) return null;

  return (
    <>
      <CommonButton
        onClick={() => setModal(true)}
        title={t("general.send_sms")!}
        className="main"
      />
      <CommonModal
        open={modal}
        setOpen={(state) => setModal(state)}
        width={1000}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <StudentNames students={selectedRows} />
            <SelectIntegration />
            <SmsTemplates />
            <SelectTemplates />

            <div className="flex justify-end mt-2">
              <CommonButton
                type="submit"
                title={t("general.send_sms")!}
                className="main"
              />
            </div>
          </form>
        </FormProvider>
      </CommonModal>
    </>
  );
};

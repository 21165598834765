import { useApi } from "hooks/useApi/useApiHooks";
import { FC } from "react";
import { TableComponent } from "../Table";
import { TableContainerMain } from "components/elements/table/Table.style";
import TablePagination from "components/elements/table/components/tablePagination";

const defaultLimit = 20;
export interface IListProps {
  params: string | null;
}
export interface ISubject {
  subjectId: string;
  subjectName: number;
  mark: number;
  month: number;
  type: string;
  year: number;
}
export interface IResponse {
  data: Array<{
    _id: string;
    averageScore: 59;
    class: {
      _id: string;
      grade: number;
      letter: string;
    };
    student: {
      _id: string;
      deletedAt: 0;
      firstName: string;
      lastName: string;
      fullName: string;
    };
    subjects: ISubject[];
  }>;

  total: number;
}

export const List: FC<IListProps> = ({ params }) => {
  const { data } = useApi<IResponse>(
    `seasonal-mark/bysubjects?${params}`,
    undefined,
    {
      enabled: !!params,
    }
  );

  if (!params) return null;

  return (
    <div className="bg-bgMain p-4 rounded-lg mt-3 h-[calc(100%-180px)]">
      <TableComponent data={data?.data?.data as any} />
      <TableContainerMain>
        <TablePagination
          totalData={data?.data?.total as number}
          tableData={data?.data?.data!}
          defaultLimit={defaultLimit}
        />
      </TableContainerMain>
    </div>
  );
};

import { Button, Grid } from "@mui/material";
import { SelectBtnIcon, TopToBottomIcon } from "assets/svgs";
import {
  AutoCompleteFilter,
  CommonModal,
  RangeDatePicker,
  Table,
} from "components";
import { reRenderTable } from "components/elements/table/reducer/table.slice";
import { class_grade } from "constants/pageConstants";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRoleManager } from "services/useRoleManager";
import { OrderHeaderStyled, SecondRowShow } from "styles/global.style";
import { useAppDispatch } from "../../../../../store/storeHooks";
import ImportModal from "../components/ImportModal";
import { StudentStyle } from "./Students.style";
import { useStudentsColumns } from "./students.columns";
import { getFromSessionStorage, saveToSessionStorage } from "utils/session";
import { IStudent } from "../../types";
import { SendSms } from "../../components/SendSms";

const ArchiveStudents = () => {
  const isOpen = getFromSessionStorage("studentFilter");
  const [isTrue, setIsTrue] = useState<boolean>(isOpen === "open");
  const [selectedRows, setSelectedRows] = useState<IStudent[]>([]);
  const [wantDelete, setWantDelete] = useState<string>("");

  const dis = useAppDispatch();

  const { t } = useTranslation();

  const columns = useStudentsColumns();
  const navigate = useNavigate();

  const hasAccess = useRoleManager();

  const { mutate, status } = useApiMutation(
    `student/inactivate/${wantDelete}`,
    "put",
    {}
  );

  useEffect(() => {
    if (status === "success") {
      dis(reRenderTable(true));
      setWantDelete("");
    }
  }, [status]);

  const handleChange = () => {
    setIsTrue(!isTrue);
    saveToSessionStorage("studentFilter", !isTrue ? "open" : "close");
  };

  const renderHeader = (
    <OrderHeaderStyled>
      <SendSms selectedRows={selectedRows} />
      <Button className="filter_btn" onClick={handleChange}>
        <div>
          <SelectBtnIcon />
          <span>{t("LABELS.FILTER.FILTER_TITLE")}</span>
        </div>
        <div className={isTrue ? "icon active" : "icon"}>
          <TopToBottomIcon />
        </div>
      </Button>
      <ImportModal />
    </OrderHeaderStyled>
  );

  const renderSecondRow = (
    <SecondRowShow in={isTrue}>
      <Grid
        container
        spacing={1}
        mb={"12px"}
        display={"flex"}
        justifyContent={"flex-end"}
        paddingRight={"8px"}
      >
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            filterName="language"
            placeholder={t("LABELS.LEARNING_LANGUAGE")!}
            options={[
              {
                _id: "uz",
                name: "O'zbek",
              },
              {
                _id: "ru",
                name: "Русский",
              },
            ]}
          />
        </Grid>
        {/* <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            filterName="paymentType"
            placeholder={t("LABELS.PAYMENT_TYPE")!}
            options={PAYMENT_TYPE.map((val) => ({
              ...val,
              name: t(val.name),
            }))}
          />
        </Grid> */}
        <Grid item md={3} xs={3}>
          <RangeDatePicker />
        </Grid>
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.CLASS_GRADE")!}
            filterName="grade"
            options={class_grade.map((value) => ({
              ...value,
              _id: value._id,
              name: t(value.name),
            }))}
            multiple
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.STATE_BALANCE")!}
            filterName="showDebitors"
            options={[
              { _id: "false", name: t("general.have") },
              { _id: "true", name: t("general.debtor") },
            ]}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.STATE")!}
            filterName="state"
            options={[
              { _id: "active", name: t("general.active") },
              { _id: "inactive", name: t("general.not_active") },
            ]}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <AutoCompleteFilter
            placeholder={t("LABELS.CONTRACT_NUMBER")!}
            filterName="hasContract"
            options={[
              { _id: "true", name: t("general.yes") },
              { _id: "false", name: t("general.no") },
            ]}
          />
        </Grid>
      </Grid>
    </SecondRowShow>
  );

  return (
    <StudentStyle>
      <Table
        dataUrl="/students/pagin"
        columns={columns}
        searchable
        selection
        exportBtn
        headerChildren={renderHeader}
        headerChildrenSecondRow={renderSecondRow}
        title={t("general.students")}
        addButtonTitle={t("general.add_student")!}
        setRowSelection={setSelectedRows}
        archiveIcon={true}
        onAddButton={
          hasAccess("editStudents")
            ? () => navigate(`/students/details`)
            : undefined
        }
        // onEditColumn={
        //   hasAccess("editStudents")
        //     ? (row) => {
        //         navigate(`/students/details/${row._id}`);
        //       }
        //     : undefined
        // }
        onDeleteColumn={
          hasAccess("inactivateStudents")
            ? (row) => setWantDelete(row._id)
            : undefined
        }
        processingParams={(params) => {
          return {
            ...params,
            grade: JSON.stringify(
              JSON.parse(params?.grade || "[]")?.map(
                (value: Record<string, any>) => value?._id
              )
            ),
          };
        }}
        deleteTitle={t("general.unarchive")}
        exQueryParams={{ state: "archive" }}
      />

    <CommonModal
        title={t("general.really_unarchive")!}
        open={!!wantDelete}
        setOpen={setWantDelete}
        onSave={() => mutate({})}
        status={status}
      />

    </StudentStyle>
  );
};

export default ArchiveStudents;

import { TextField } from "@mui/material";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import { TextInputStyled } from "../TextInput.style";
import { Control, Controller, FieldPath } from "react-hook-form";
import { TRules } from "types/form.types";
import { useTranslation } from "react-i18next";
interface IMoneyInput<FormNames extends Record<string, any>> {
  control: Control<FormNames>;
  name: FieldPath<FormNames>;
  rules?: TRules<FormNames>;
  label?: string;
  placeholder?: string | number;
  disabled?: boolean;
  inputProps?: any;
  onCustomChange?: any;
  returnValueAsString?: boolean;
}
function MoneyInput<FormNames extends Record<string, any>>({
  control,
  name,
  disabled,
  label,
  placeholder,
  rules,
  inputProps,
  onCustomChange,
  returnValueAsString = false,
}: IMoneyInput<FormNames>) {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextInputStyled>
          {label && (
            <label htmlFor="">
              {label}
              <span>
                {label && rules?.required ? (
                  <span className="text-error mt-1">*</span>
                ) : null}
              </span>
            </label>
          )}
          <NumericFormat
            // {...field}
            // onChange={(e) => e.target.value}
            value={field.value || ""}
            onBlur={field.onBlur}
            placeholder={String(placeholder)}
            disabled={disabled}
            inputProps={inputProps}
            InputProps={{
              ref: field.ref,
              sx: {
                "& fieldset": { border: "none" },
              },
            }}
            name={field.name}
            allowLeadingZeros={true}
            thousandSeparator=" "
            allowNegative={true}
            customInput={TextField}
            onValueChange={(val) => {
              if (returnValueAsString) {
                field.onChange(val.value || "");
                onCustomChange?.(val.value);
              } else {
                field.onChange(val.floatValue || "");
                onCustomChange?.(val.floatValue);
              }
            }}
          />
          {fieldState.error && (
            <>
              <h6 className="text-error">
                {label} {t(fieldState.error.message!)}
              </h6>
            </>
          )}
        </TextInputStyled>
      )}
    />
  );
}

export default MoneyInput;

import { Box, Typography } from "@mui/material";

import CheckTop from "../../../assets/images/Top.png";
import CheckBottom from "../../../assets/images/Bottom.png";
import LoadingImg from "../../../assets/images/loadingImg.png";

import React, { forwardRef } from "react";

import "./print.style.css";
import { ReceiptTranslation } from "changeLang/ReceiptTranslation.constant";
import { get } from "lodash";

interface CheckProps {
  header?: string;
  logo?: string;
  footer?: string;
  transaction: {
    show: boolean;
    children: { show: boolean; name: string; data?: string }[];
  };
  title: {
    show: boolean;
    children: { show: boolean; name: string; data?: string }[];
  };
  // balance?: {
  //   show?: boolean;
  //   children?: { show: boolean; name: string; data?: string }[];
  // };
  children?: React.ReactElement;
  branchName?: string;
  cashierNameInfo?: string;
  studentName?: string;
  createdAt?: string;
  cashbox?: string;
  paymentType?: string;
  comment?: string;
  transactionType?: string;
  amount?: string;
  language: string;
  // afterAmount?: string;
  // beforeAmount?: string;
}

const Check = forwardRef<HTMLDivElement, CheckProps>((props, ref) => {
  const {
    title,
    transaction,
    footer,
    logo,
    header,
    children,
    branchName,
    cashierNameInfo,
    studentName,
    createdAt,
    cashbox,
    paymentType,
    comment,
    transactionType,
    language,
    // balance,
    amount,
    // beforeAmount,
    // afterAmount
  } = props;


  const tt = (translationKey: any) => {
    return get(
      ReceiptTranslation,
      `${language}.${translationKey}`,
      translationKey
    );
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{ position: "sticky", top: "-40px" }}
      >
        <img width={"100%"} src={CheckTop} alt="" />
        <Box>
          <div className="check" ref={ref}>
            {title.show && (
              <ul className="check_list">
              
                {title.children.find(
                  (child) => child.name === "branch" && child.show 
                ) && (
                  <li style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      fontWeight={600}
                      fontSize={16}
                      textAlign={"center"}
                      pt={2}
                    >
                      {branchName || "Branch Name"}
                    </Typography>
                  </li>
                )}
                {title.children?.find(
                  (child) => child.name === "header" && child.show
                ) && (
                  <li style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      fontWeight={500}
                      fontSize={15}
                      textAlign={"center"}
                    >
                      {header || "Title"}
                    </Typography>
                  </li>
                )}
                {title.children.find(
                  (child) => child.name === "logo" && child.show
                ) &&
                  logo && (
                    <li>
                      <img
                        className="CheckImg"
                        src={
                          logo
                            ? `${process.env.REACT_APP_BASE_URL}${logo}`
                            : LoadingImg
                        }
                        alt="Logo"
                        style={{
                          width: "100%",
                          maxWidth: "250px",
                          height: "auto",
                          padding: "10px",
                          margin: "0 auto",
                          display: "block",
                          borderRadius: "8px",
                        }}
                      />
                    </li>
                  )}
              </ul>
            )}

            {transaction.show && (
              <ul className="check_list">
                <li>
                  {transaction.children.find(
                    (child) => child.name === "cashierName" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                        {transaction.children.find(
                          (child) => child.name === "cashierName" && child.show
                        )?.name === "cashierName"
                          ? `${tt('settings.receipt.transaction.cashier_name')}:`
                          : "Kassir"}
                      </span>{" "}
                      <span>{cashierNameInfo || "Bobur Bunyodjonov"}</span>
                    </div>
                  )}
                </li>
                <li>
                  {transaction.children.find(
                    (child) => child.name === "customerName" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                        {transaction.children.find(
                          (child) => child.name === "customerName" && child.show
                        )?.name === "customerName"
                          ? `${tt('settings.receipt.transaction.student')}:`
                          : ""}
                      </span>{" "}
                      <span>{studentName || "Bobur Bunyodjonov"}</span>
                    </div>
                  )}
                </li>
                <li>
                  {transaction.children.find(
                    (child) => child.name === "date" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                        {transaction.children.find(
                          (child) => child.name === "date" && child.show
                        )?.name === "date"
                          ? `${tt('settings.receipt.transaction.sale_date')}:`
                          : ""}
                      </span>{" "}
                      <span>{createdAt || "27.08.2024 17:33"}</span>
                    </div>
                  )}
                </li>
                <li>
                  {transaction.children.find(
                    (child) => child.name === "cashbox" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                        {transaction.children.find(
                          (child) => child.name === "cashbox" && child.show
                        )?.name === "cashbox"
                          ? `${tt('settings.receipt.transaction.cashbox')}:`
                          : ""}
                      </span>{" "}
                      <span>{cashbox || "Bobur Bunyodjonov"}</span>
                    </div>
                   )}
                </li>
                <li>
                  {transaction.children.find(
                    (child) => child.name === "paymentType" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                        {transaction.children.find(
                          (child) => child.name === "paymentType" && child.show
                        )?.name === "paymentType"
                          ? `${tt('settings.receipt.transaction.payment_type')}:`
                          : ""}
                      </span>{" "}
                      <span>{paymentType || "Naqt"}</span>
                    </div>
                  )}
                </li>
                <li>
                  {transaction.children.find(
                    (child) => child.name === "transactionType" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                      {transaction.children.find(
                          (child) => child.name === "transactionType" && child.show
                        )?.name === "transactionType"
                          ? `${tt('settings.receipt.transaction.transaction_type')}:`
                          : ""}
                      </span>{" "}
                      <span>{transactionType || "O'quvchi kirim"}</span>
                    </div>
                  )}
                </li>
                <li>
                  {transaction.children.find(
                    (child) => child.name === "comment" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                      {transaction.children.find(
                          (child) => child.name === "comment" && child.show
                        )?.name === "comment"
                          ? `${tt('settings.receipt.transaction.note')}:`
                          : ""}
                      </span>{" "}
                      <span>{comment || "Bu Izoh"}</span>
                    </div>
                  )}
                </li>
                <li>
                  <div className="flex justify-between w-100">
                    <span style={{ fontWeight: "700" }}>
                      {tt('settings.receipt.transaction.amount') + ':'}
                    </span>{" "}
                    <span>{amount || "500 000"} so'm</span>
                  </div>
                </li>
              </ul>
            )}

            {/* {balance.show && (
              <ul className="check_list">
                <li>
                  {balance.children.find(
                    (child) => child.name === "beforeBalance" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                        {formatName("beforeBalance")}:
                      </span>{" "}
                      <span>{beforeAmount || "Bobur Bunyodjonov"}</span>
                    </div>
                  )}
                </li>
                  
                <li>
                  {balance.children.find(
                    (child) => child.name === "afterBalance" && child.show
                  ) && (
                    <div className="flex justify-between w-100">
                      <span style={{ fontWeight: "700" }}>
                        {formatName("afterBalance")}:
                      </span>{" "}
                      <span>{afterAmount || "500 000"} so'm</span>
                    </div>
                  )}
                </li>
              </ul>
            )} */}

            <Typography
              fontWeight={500}
              fontSize={17}
              textAlign={"center"}
              p={2}
            >
              {footer}
            </Typography>
          </div>
          {children}
        </Box>
        <img width={"100%"} src={CheckBottom} alt="" />
      </Box>
    </>
  );
});

export default Check;

import { FC } from "react";
import { IIntegration } from "../../constants/Integration.type";
import { generateUploadUrl } from "utils/generateUploadUrl";
import { IntegrationCardWrapper } from "./IntegrationCard.style";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface IIntegrationCardProps extends IIntegration {
  setModal: (state: string | boolean) => void;
}

export const IntegrationCard: FC<IIntegrationCardProps> = ({
  images,
  name,
  isInstalled,
  setModal,
  _id,
}) => {
  const { t } = useTranslation();
  return (
    <IntegrationCardWrapper onClick={() => setModal(_id)}>
      <div className="image-wrapper">
        <img src={generateUploadUrl(images[0])} alt={name} />
      </div>
      <p className="card-name">{name}</p>
      {isInstalled ? (
        <Button className="card-button">{t("integrations.installed")}</Button>
      ) : (
        <Button className="card-button">{t("integrations.install")}</Button>
      )}
    </IntegrationCardWrapper>
  );
};

import styled from "styled-components";

interface ITextInputStyled {
  isError?: boolean;
  disabled?: boolean;
}

export const TextInputStyled = styled.div<ITextInputStyled>`
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.primary.disabled : theme.primary.dark};
    display: block;
    margin-bottom: 4px;
    span {
      color: red;
    }
  }
  .MuiFormControl-root {
    min-height: 38px;

    .MuiInputBase-root {
      min-height: 42px;
      padding: 0px 12px !important;
      border: none;
      font-size: 14px;
      font-weight: 500;
      border-radius: 12px;
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.background.disabled : theme.background.info};
      border: 2px solid ${({ theme }) => theme.background.info};
      color: ${({ theme }) => theme.primary.dark};

      &:hover {
        border: 2px solid
          ${({ theme, disabled }) =>
            disabled ? "inherit" : theme.background.primary};
      }

      input {
        height: 42px !important;
        padding: 0;
        color: ${({ theme }) => theme.primary.dark};
      }

      textarea {
        /* max-height: 100px; */
        overflow: auto !important;
      }

      /* &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8e8ea9;
      } */

      &:focus {
        border: 2px solid ${({ theme }) => theme.background.primary};
      }
    }
  }

  h6 {
    position: absolute;
    bottom: -18px;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.primary.dark};
  }
`;

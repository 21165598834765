import styled from "styled-components";

export const FormRadioStyled = styled("div")`
  .MuiFormLabel-root {
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.primary.dark};
    &.Mui-focused {
      color: ${({ theme }) => theme.primary.main};
    }
  }
  .MuiButtonBase-root {
    color: ${({ theme }) => theme.primary.dark};
    &.Mui-checked {
      color: ${({ theme }) => theme.primary.main} !important;
    }
  }
  .MuiTypography-root {
    font-size: 12px;
    color: ${({ theme }) => theme.primary.dark};
  }
`;

import { Box } from "@mui/material";
import { Sidebar } from "components";
import { motion } from "framer-motion";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { RootState } from "store/store";
import { useAppSelector } from "store/storeHooks";
import {
  PrivateContainer,
  SIDEBAR_CLOSE,
  SIDEBAR_OPEN,
} from "styles/global.style";

const hasNavbar = (pathname: string) =>
  !["purchase/create", "contract/templates", "purchase/", "contract/view"].some(
    (path) => pathname.includes(path)
  );
const scrollPages = (pathname: string) =>
  [
    "order",
    "contract",
    "queue",
    "purchase",
    "income",
    "warehouse/products",
    "stock-car",
  ].some((path) => pathname.includes(path));
const Layout = () => {
  const navigator = useNavigate();
  const location = useLocation();

  const { value } = useAppSelector((state: RootState) => state.sideBarData);

  const isAuth = useAppSelector((store) => store.LoginState.isAuth);
  return isAuth ? (
    <>
      <Sidebar />

      <motion.div
        animate={{
          // width: "100%",
          paddingLeft: value ? SIDEBAR_OPEN : SIDEBAR_CLOSE,
        }}
        style={{ minHeight: "100vh" }}
        className="home-container"
      >
        <PrivateContainer
          style={{
            paddingTop: "0",
            height: "100vh",
            overflow: "auto",
            boxSizing: "border-box",
          }}
        >
          <Box
            height={"100vh"}
            sx={{
              overflowY: scrollPages(location.pathname) ? "scroll" : "auto",
              background: "#f5f5f5",
            }}
          >
            <Outlet />
          </Box>
        </PrivateContainer>
      </motion.div>
    </>
  ) : null;
};

export default Layout;

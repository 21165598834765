export const INTEGRATION_FILTER_LIST = [
  {
    label: "All",
    id: "all",
  },
  {
    label: "Payment type",
    id: "payment",
  },
  {
    label: "Sms",
    id: "sms",
  },
];

import React, { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const url = "wss://backend.eduschool.uz";

export const useSocket = () => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (process.env.REACT_APP_SOCKET_URL) {
      const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
        path: "/moderator-chat-api/socket.io",
        extraHeaders: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        transports: ["polling", "websocket"],
      });
      setSocket(newSocket);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.connect();
      // console.log("socket", socket);
      socket?.on("connect", () => {
        // console.log("--🌠socket-connected: Global Socket");
      });

      return () => {
        socket?.off("connect");
      };
    }
  }, [socket]);

  return socket;
};

import { IIntegration } from "pages/settings/integrations/constants/Integration.type";
import { FC } from "react";
import { generateUploadUrl } from "utils/generateUploadUrl";

export interface IPreviewProps {
  data: IIntegration;
}

export const Preview: FC<IPreviewProps> = ({ data }) => {
  return (
    <div>
      <img
        className="preview__image"
        src={generateUploadUrl(data?.images[0]!)}
        alt={data?._id}
      />
      {data.description}
    </div>
  );
};

import { Button } from "@mui/material";
import useQueryHook from "hooks/useQueryHook";
import { FC } from "react";
import { INTEGRATION_FILTER_LIST } from "../../constants/filterList";
import { StyledNavbarButtonGroup } from "./Navbar.style";

export interface INavbarProps {}

export const Navbar: FC<INavbarProps> = () => {
  const { AddQueryParams, QueryParams } = useQueryHook();

  function handleClick(service: string) {
    AddQueryParams({ service });
  }
  function getButtonVariant(service: string): "outlined" | "contained" {
    return QueryParams?.service === service ? "contained" : "outlined";
  }

  return (
    <StyledNavbarButtonGroup
      variant="outlined"
      aria-label="Integration button group"
    >
      {INTEGRATION_FILTER_LIST.map((item) => (
        <Button
          onClick={() => handleClick(item.id)}
          variant={getButtonVariant(item.id)}
          title={item.label}
        >
          {item.label}
        </Button>
      ))}
    </StyledNavbarButtonGroup>
  );
};
